define("ocularium-frontend-core/services/navigation/breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    breadcrumbs: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('breadcrumbs', []);
    },
    addBreadcrumb: function addBreadcrumb(item) {
      this.get('breadcrumbs').pushObject(item);
    },
    removeBreadcrumb: function removeBreadcrumb() {
      this.get('breadcrumbs').popObject();
    },
    clearBreadcrumbs: function clearBreadcrumbs() {
      this.get('breadcrumbs').clear();
    },
    navigateByBreadcrumb: function navigateByBreadcrumb(item) {
      var index = this.get('breadcrumbs').indexOf(item);

      if (index > 0) {
        this.get('breadcrumbs').removeAt(index + 1, this.get('breadcrumbs.length') - index);
      } else {
        this.get('breadcrumbs').splice(index + 1);
      }

      this.get('router').transitionTo(item['url'], item['navId']);
    }
  });

  _exports.default = _default;
});