define("ocularium-frontend-core/lib/data/labels/defaultModuleLabels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.labels = void 0;
  var labels = {
    "global": ["back", "home", "yes", "no"],
    "InteractiveMap": ["explore"],
    "SpeedQuiz": ["graduationBadLabel", "graduationNormalLabel", "graduationGoodLabel", "scoreLabel"],
    "DigitalLabels": ["explore", "factsAndFigures"],
    "MediaBrowser": ["explore"]
  };
  _exports.labels = labels;
});