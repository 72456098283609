define("ember-bootstrap/templates/components/bs-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gT4uCoTz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,1]],null,{\"statements\":[[0,\"  \"],[13,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,[\"hasSubmitButton\"]]],null,{\"statements\":[[0,\"    \"],[4,\"component\",[[22,[\"buttonComponent\"]]],[[\"onClick\"],[[26,\"action\",[[21,0,[]],[22,[\"onClose\"]]],null]]],{\"statements\":[[1,[20,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"component\",[[22,[\"buttonComponent\"]]],[[\"type\",\"onClick\",\"disabled\"],[[22,[\"submitButtonType\"]],[26,\"action\",[[21,0,[]],[22,[\"onSubmit\"]]],null],[22,[\"submitDisabled\"]]]],{\"statements\":[[1,[20,\"submitTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[22,[\"buttonComponent\"]]],[[\"type\",\"onClick\"],[\"primary\",[26,\"action\",[[21,0,[]],[22,[\"onClose\"]]],null]]],{\"statements\":[[1,[20,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/footer.hbs"
    }
  });

  _exports.default = _default;
});