define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a462irma",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,[\"_isOpen\"]]],null,{\"statements\":[[4,\"ember-popper\",null,[[\"id\",\"class\",\"ariaRole\",\"placement\",\"popperTarget\",\"renderInPlace\",\"popperContainer\",\"modifiers\"],[[26,\"concat\",[[22,[\"dropdownElementId\"]],\"__menu\"],null],[26,\"concat\",[\"dropdown-menu \",[22,[\"alignClass\"]],[26,\"if\",[[22,[\"isOpen\"]],\" show\"],null],\" \",[22,[\"class\"]]],null],[22,[\"ariaRole\"]],[22,[\"popperPlacement\"]],[22,[\"toggleElement\"]],[22,[\"_renderInPlace\"]],\"#ember-bootstrap-wormhole\",[22,[\"popperModifiers\"]]]],{\"statements\":[[0,\"    \"],[13,1,[[26,\"hash\",null,[[\"item\",\"link-to\",\"divider\"],[[26,\"component\",[[22,[\"itemComponent\"]]],null],[26,\"component\",[[22,[\"linkToComponent\"]]],null],[26,\"component\",[[22,[\"dividerComponent\"]]],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });

  _exports.default = _default;
});