define("ember-bootstrap/templates/components/bs-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wsIrqpAx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[13,1,[[26,\"hash\",null,[[\"button\",\"toggle\",\"menu\",\"toggleDropdown\",\"openDropdown\",\"closeDropdown\",\"isOpen\"],[[26,\"component\",[[22,[\"buttonComponent\"]]],[[\"dropdown\",\"onClick\"],[[21,0,[]],[26,\"action\",[[21,0,[]],\"toggleDropdown\"],null]]]],[26,\"component\",[[22,[\"toggleComponent\"]]],[[\"dropdown\",\"inNav\",\"onClick\"],[[21,0,[]],[22,[\"inNav\"]],[26,\"action\",[[21,0,[]],\"toggleDropdown\"],null]]]],[26,\"component\",[[22,[\"menuComponent\"]]],[[\"isOpen\",\"direction\",\"inNav\",\"toggleElement\",\"dropdownElementId\"],[[22,[\"isOpen\"]],[22,[\"direction\"]],[22,[\"inNav\"]],[22,[\"toggleElement\"]],[22,[\"elementId\"]]]]],[26,\"action\",[[21,0,[]],\"toggleDropdown\"],null],[26,\"action\",[[21,0,[]],\"openDropdown\"],null],[26,\"action\",[[21,0,[]],\"closeDropdown\"],null],[22,[\"isOpen\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown.hbs"
    }
  });

  _exports.default = _default;
});