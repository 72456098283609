define("ember-bootstrap/templates/components/bs-tooltip/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TWqJa1eG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-popper\",null,[[\"id\",\"class\",\"ariaRole\",\"placement\",\"renderInPlace\",\"popperTarget\",\"modifiers\",\"popperContainer\",\"onCreate\",\"onUpdate\"],[[22,[\"id\"]],[22,[\"popperClass\"]],[22,[\"ariaRole\"]],[22,[\"placement\"]],[22,[\"renderInPlace\"]],[22,[\"popperTarget\"]],[22,[\"popperModifiers\"]],\"#ember-bootstrap-wormhole\",[26,\"action\",[[21,0,[]],\"updatePlacement\"],null],[26,\"action\",[[21,0,[]],\"updatePlacement\"],null]]],{\"statements\":[[0,\"  \"],[6,\"div\"],[11,\"class\",[20,\"arrowClass\"]],[8],[9],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"tooltip-inner\"],[8],[0,\"\\n    \"],[13,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip/element.hbs"
    }
  });

  _exports.default = _default;
});