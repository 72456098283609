define("ocularium-frontend-core/services/unity", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Service.extend(Ember.Evented, {
    intl: Ember.inject.service('intl'),
    gameInstance: null,
    unityLoadComplete: false,
    init: function init() {
      this._super();

      this.get('intl');
    },
    langObserver: Ember.observer('intl.locale', function () {
      var locale = this.get('intl.locale')[0];
      this.changeLanguage(locale);
    }),
    setupUnityContainer: function setupUnityContainer(assetPath, assetJson) {
      var _this2 = this;

      if (!this.loadUnityContent()) {
        this.set('unityLoadComplete', true);
        return;
      }

      Ember.run.later(function () {
        (0, _jquery.default)('body').append('<div class="application__unity loading"><div class="unity--container--loading">Loading Unity3D Content</div><div class="unity--container"><div id="unity-content"></div></div></div>');
        Ember.run.later(function () {
          _this2.loadUnity('unity-content', assetPath, assetJson);
        }, 0);
      }, 0);
    },
    showUnityContainer: function showUnityContainer() {
      var contentClassName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'unity--content--container';
      Ember.run.later(function () {
        (0, _jquery.default)('.unity--container').appendTo('.' + contentClassName);
      }, 0);
    },
    hideUnityContainer: function hideUnityContainer() {
      (0, _jquery.default)('.unity--container').appendTo('.application__unity');
    },
    loadUnity: function loadUnity(domElementId, assetPath, assetJson) {
      var _this3 = this;

      if (!this.loadUnityContent()) {
        this.set('unityLoadComplete', true);
        return;
      }

      _jquery.default.getScript(assetPath + '/UnityLoader.js').done(function () {
        _this3.gameInstance = UnityLoader.instantiate(domElementId, assetPath + "/" + assetJson);
      });

      this.setupUnityCallbacks();
    },
    loadUnityContent: function loadUnityContent() {
      return window.ocularium.unity === undefined || window.ocularium.unity.loadUnity !== undefined && window.ocularium.unity.loadUnity === true;
    },
    setupUnityCallbacks: function setupUnityCallbacks() {
      var _this = this;

      window.LoadComplete = function () {
        _this.set('unityLoadComplete', true);

        (0, _jquery.default)('.application__unity').removeClass('loading');
        (0, _jquery.default)('.application__unity').find('.unity--container--loading').remove();

        _this.sendToUnity("SetupUnity", window.ocularium.unity !== undefined ? window.ocularium.unity : {});

        _this.trigger('unityLoadComplete');
      };

      window.SendToBrowser = function (response) {
        _this.trigger('getFromUnity', JSON.parse(response));
      };
    },
    sendToUnity: function sendToUnity(command) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var parameters = {
        "command": command,
        "data": data
      };

      if (this.gameInstance !== null && this.unityLoadComplete) {
        this.gameInstance.SendMessage('ocularium-web', 'SendToUnity', JSON.stringify(parameters));
      }
    },
    changeLanguage: function changeLanguage(language) {
      this.sendToUnity("ChangeLanguage", {
        'language': language
      });
    },
    changeScene: function changeScene(sceneName) {
      this.sendToUnity("ChangeScene", {
        'scene': sceneName
      });
    },

    /*
        setup labels to pass to unity
    */
    setupLabels: function setupLabels(labelList) {
      var _this4 = this;

      this.labels = {};
      Object.keys(labelList).forEach(function (locale) {
        _this4.addToLabels(labelList[locale], locale, '');
      });
      this.sendToUnity("SetupLabels", {
        'labels': this.labels
      });
    },
    addToLabels: function addToLabels(labelList, locale, parentKey) {
      var _this5 = this;

      Object.keys(labelList).forEach(function (localeKey) {
        var localeString = labelList[localeKey];

        if (_typeof(localeString) === 'object') {
          _this5.addToLabels(labelList[localeKey], locale, localeKey);
        } else {
          var fullKey = parentKey + (parentKey === '' ? '' : '.') + localeKey;

          if (_this5.labels[locale] === undefined) {
            _this5.labels[locale] = {};
          }

          _this5.labels[locale][fullKey] = localeString;
        }
      });
    }
  });

  _exports.default = _default;
});