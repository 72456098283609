define("ocularium-frontend-core/services/io/rfid", ["exports", "ocularium-shared/lib/processors/rfidGuideProcessor", "jquery"], function (_exports, _rfidGuideProcessor, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service('router'),
    userService: Ember.inject.service('user'),
    intl: Ember.inject.service('intl'),
    crossbarService: Ember.inject.service('io/crossbar'),
    isScanned: false,
    isInitialized: false,
    initialize: function initialize() {
      var reroute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "index";
      var allowUserOverride = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.set('reroute', reroute);
      this.set('allowUserOverride', allowUserOverride);
      this.registerRfidMessageProcessor();
      this.setupConnection();
      this.set('isInitialized', true);
    },
    registerRfidMessageProcessor: function registerRfidMessageProcessor() {
      var _this = this;

      var messageProcessor = this.get('crossbarService').get('messageProcessor');
      messageProcessor.registerEventSubscriber({
        "RFIDGuideCommandProcessor": function RFIDGuideCommandProcessor(processor) {
          return _this.subscribeRFIDEvents(processor);
        }
      }, new _rfidGuideProcessor.default());
    },
    subscribeRFIDEvents: function subscribeRFIDEvents(processor) {
      var _this2 = this;

      processor.on('newScan', function (e) {
        console.log('scan received: ', e.params);

        if (e.params.device === window.ocularium.deviceId) {
          _this2.getUserData(e.params.tag);
        }
      });
    },
    setupConnection: function setupConnection() {
      var _this3 = this;

      var connection = new phidget22.Connection(8989, 'localhost');
      var ch = new phidget22.RFID();

      ch.onError = function (error) {
        _this3.onError(error);
      };

      ch.onAttach = function (channel) {
        _this3.onAttach(channel);
      };

      ch.onDetach = function (ch) {
        console.log(ch, "detach");
      };

      connection.connect().then(function () {
        console.log("-- connection open --");
        ch.open().then(function () {
          console.log("-- channel open --");
        }).catch(function (err) {
          console.log('-- failed to open the channel:' + err);
        });
      }).catch(function (error) {
        console.warn("-- rfid error ", error);
      });
    },
    onAttach: function onAttach(ch) {
      var _this4 = this;

      ch.onTag = function (e) {
        _this4.onScanReceived(e);
      };
    },
    onError: function onError(e) {
      console.log(e);
    },
    onScanReceived: function onScanReceived(tag) {
      console.log("-- scan received " + tag + " -- getting user data");
      this.getUserData(tag);
    },
    getUserData: function getUserData(tag) {
      var _this5 = this;

      var prefix = window.ocularium['api']['host'];
      this.set('isScanned', false);

      _jquery.default.ajax({
        type: "GET",
        contentType: 'application/json',
        url: prefix + 'rfid/' + String(tag) + '?device=' + window.ocularium.deviceId,
        success: function success(userData) {
          return _this5.onUserDataSuccess(userData);
        },
        error: function error(e) {
          return _this5.onUserDataFailed(e);
        }
      });
    },
    onUserDataSuccess: function onUserDataSuccess(userData) {
      var _this6 = this;

      console.log("-- userdata received --", userData);
      var activeUser = this.get('userService').getUser();

      if (activeUser) {
        if (activeUser["tag"] === userData["tag"]) {
          console.log("-- user already logged in --");
        } else {
          console.log("-- user isRegistered: ", userData["additionalInformation"]["isRegistered"], " --");
          this.get('userService').setTempUser(userData);

          if (this.get('allowUserOverride')) {
            this.loginUser();
          } else {
            Ember.run.cancel(this.get('delayLoginConfirmationGone'));
            this.set('isLoginConfirmationVisible', true);
            this.set('delayLoginConfirmationGone', Ember.run.later(function () {
              _this6.set('isLoginConfirmationVisible', false);
            }, 20000));
          }
        }
      } else {
        console.log("-- user isRegistered: ", userData["additionalInformation"]["isRegistered"], " --");
        this.get('userService').setTempUser(userData);
        this.loginUser();
      }
    },
    onUserDataFailed: function onUserDataFailed(e) {
      console.log("-- an error occured while getting the userdata --", e);
    },
    loginUser: function loginUser() {
      var _this7 = this;

      Ember.run.cancel(this.get('delayUserLogin'));
      var newUser = this.get('userService').getTempUser();
      this.get('router').transitionTo(this.get('reroute'));
      this.get('userService').setUser(newUser);
      this.set('delayUserLogin', Ember.run.later(function () {
        _this7.get('intl').setLocale(newUser.language);

        _this7.set('isScanned', true);

        if (!_this7.get('allowUserOverride')) {
          Ember.run.cancel(_this7.get('delayLoginConfirmationGone'));

          _this7.set('isLoginConfirmationVisible', false);
        }
      }, 0));
    },
    logoutUser: function logoutUser() {
      this.get('userService').clearUser();
    },
    updateUserData: function updateUserData() {
      var _this8 = this;

      var prefix = window.ocularium['api']['host'];
      var userData = JSON.stringify(this.get('userService').getUser());

      _jquery.default.ajax({
        type: "PUT",
        contentType: 'application/json',
        data: userData,
        url: prefix + 'rfid/' + String(userData["tag"]) + '?device=' + window.ocularium.deviceId,
        success: function success(res) {
          return _this8.onUserUpdateSuccess(res);
        },
        error: function error(e) {
          return _this8.onUserUpdateFailed(e);
        }
      });
    },
    onUserUpdateSuccess: function onUserUpdateSuccess(res) {
      console.log("-- updating user succeeded --", res);
    },
    onUserUpdateFailed: function onUserUpdateFailed(reason) {
      console.log("-- updating user failed --", reason);
    }
  });

  _exports.default = _default;
});