define("ocularium-frontend-core/lib/constants/ocularErrors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    NO_OCULARIUM_JS: {
      name: "OculariumError",
      code: 'NO_OCULARIUM_JS',
      message: 'The file `ocularium.js` must be missing. Check your folder.'
    },
    NO_DEVICE_ID: {
      name: "OculariumError",
      code: 'NO_DEVICE_ID',
      message: 'No `device-id` was given in `ocularium.js`.'
    },
    NO_PROJECT_NAME: {
      name: "OculariumError",
      code: 'NO_PROJECT_NAME',
      message: 'No `project-name` was given in the `ocularium.js`'
    },
    NO_THEME_ID: {
      name: "OculariumError",
      code: 'NO_THEME_ID',
      message: 'The `theme-id` is missing in the API result of devices/*.'
    },
    NO_SCENARIO_ID: {
      name: "OculariumError",
      code: 'NO_SCENARIO_ID',
      message: 'The `scenario-id` is missing in the API result of devices/*.'
    },
    NO_NAVIGATION_TYPE: {
      name: "OculariumError",
      code: 'NO_NAVIGATION_TYPE',
      message: 'No `navigation-type` was given in the result of application/*.'
    },
    SCENARIO_LENGTH: {
      name: "OculariumError",
      code: 'SCENARIO_LENGTH',
      message: 'The length of a scenario entry is invalid (00:00:00 or undefined/null).'
    },
    CROSSBAR_NO_REALM: {
      name: "CrossbarError",
      code: 'CROSSBAR_NO_REALM',
      message: 'No realm assigned.'
    },
    CROSSBAR_HOST_UNREACHABLE: {
      name: "CrossbarError",
      code: 'CROSSBAR_HOST_UNREACHABLE',
      message: 'Crossbar host unreachable after three retries.'
    },
    CROSSBAR_NO_CLOSED: {
      name: "CrossbarError",
      code: 'CROSSBAR_NO_CLOSED',
      message: ''
    },
    DEVICE_NOT_FOUND: {
      name: "OculariumError",
      code: 'DEVICE_NOT_FOUND',
      message: 'The `device` was not found. Please check the `deviceId` and `api.host` in your ocularium.js file.'
    },
    NO_LANGUAGES: {
      name: "OculariumError",
      code: 'NO_LANGUAGES',
      message: 'There are no `languages` active in this scenario/project.<br/>There needs to be at least one language active.'
    },
    NO_VALID_APPLICATION: {
      name: "OculariumError",
      code: 'NO_VALID_APPLICATION',
      message: 'No valid application active.'
    },
    UNAUTHORIZED_REQUEST: {
      name: "OAuth Status Error",
      code: 'UNAUTHORIZED_REQUEST',
      message: 'Client authentication failed.'
    },
    FORBIDDEN_REQUEST: {
      name: "OAuth Status Error",
      code: 'FORBIDDEN_REQUEST',
      message: 'The unauthenticated client is not authorized to use the resource.'
    }
  };
  _exports.default = _default;
});