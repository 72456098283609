define("ember-bootstrap/templates/components/bs-accordion/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XGAFN183",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[13,1,[[26,\"hash\",null,[[\"title\",\"body\"],[[26,\"component\",[[22,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[22,[\"collapsed\"]],[22,[\"disabled\"]],[26,\"action\",[[21,0,[]],[22,[\"onClick\"]],[22,[\"value\"]]],null]]]],[26,\"component\",[[22,[\"bodyComponent\"]]],[[\"collapsed\"],[[22,[\"collapsed\"]]]]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"component\",[[22,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[22,[\"collapsed\"]],[22,[\"disabled\"]],[26,\"action\",[[21,0,[]],[22,[\"onClick\"]],[22,[\"value\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[20,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[22,[\"bodyComponent\"]]],[[\"collapsed\"],[[22,[\"collapsed\"]]]],{\"statements\":[[0,\"    \"],[13,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/item.hbs"
    }
  });

  _exports.default = _default;
});