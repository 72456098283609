define("ocularium-frontend-core/services/monitoring/bugsnag", ["exports", "bugsnag-js"], function (_exports, _bugsnagJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    bugsnagClient: null,
    initialize: function initialize(releaseStage) {
      var _this = this;

      return new Promise(function (resolve) {
        var bugsnagClient = new _bugsnagJs.default('b289d46e83c9357194ac2b3bd68a5917');
        bugsnagClient.releaseStage = releaseStage;
        bugsnagClient.notifyReleaseStages = ['production', 'staging', 'testing'];

        Ember.Error.onerror = function (error) {
          _this.sendNotification(error);
        };

        window.mimicBugsnagNotification = function () {
          _this.sendNotification("Nobody expects The Spanish Inquisition");

          return '-- bugsnag notification mimicked --';
        };

        _this.set('bugsnagClient', bugsnagClient);

        _this.addMetaData();

        resolve(true);
      });
    },
    addMetaData: function addMetaData() {
      var bugsnagClient = this.get('bugsnagClient');
      bugsnagClient.metaData = {
        deviceConfig: window['ocularium'],
        settings: {
          scenario: 'not loaded',
          currentStep: 'not loaded',
          application: 'not loaded',
          theme: 'not loaded'
        }
      };
    },
    sendNotification: function sendNotification(error) {
      var bugsnagClient = this.get('bugsnagClient'); // todo check als het nog nodig is om dit te doen bij een nieuwe versie van bugsnag

      if (bugsnagClient && bugsnagClient.releaseStage !== "development") {
        bugsnagClient.notify(error);
      }
    }
  });

  _exports.default = _default;
});