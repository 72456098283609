define("ocularium-frontend-core/lib/prefixes/ModelPrefix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getModelPrefix = getModelPrefix;

  function getModelPrefix(moduleType) {
    function isSocial() {
      return 'modules/social/';
    }

    function isGeneric() {
      var configPrefixes = {
        "postcard": "modules/postcard/",
        "DigitalLabels": "modules/digitallabels/",
        "InteractiveMap": "modules/interactivemap/",
        "Mediabrowser": "modules/mediabrowser/",
        "Mediashow": "modules/mediashow/",
        "Screensaver": "modules/screensaver/",
        "SpeedQuiz": "modules/speedquiz/",
        "StretchedMediaShow": "modules/stretchedmediashow/",
        "Unity": "modules/unity/",
        "AssetBundle": "modules/assetBundle/",
        "LobbyExperience": "modules/lobbyexperience/"
      };
      return configPrefixes[moduleType];
    }

    function isNavigation() {
      var navigationPrefixes = {
        "tile-nav": "navigation/tile-nav",
        "TileNavigation": "navigation/tile-nav",
        "TilesNavigation": "navigation/tile-nav",
        "swipe-nav": "navigation/swipe-nav",
        "SwipeNavigation": "navigation/swipe-nav",
        "age-nav": "navigation/age-nav",
        "AgeNavigation": "navigation/age-nav"
      };
      return navigationPrefixes[moduleType];
    }
    /* */


    var modelPrefixes = {
      'postcard': isGeneric,
      'DigitalLabels': isGeneric,
      'InteractiveMap': isGeneric,
      'Mediabrowser': isGeneric,
      'Mediashow': isGeneric,
      'Screensaver': isGeneric,
      'SpeedQuiz': isGeneric,
      'StretchedMediaShow': isGeneric,
      'Unity': isGeneric,
      'LobbyExperience': isGeneric,
      'TwitterWall': isSocial,
      'tile-nav': isNavigation,
      'swipe-nav': isNavigation,
      'age-nav': isNavigation,
      'TileNavigation': isNavigation,
      'TilesNavigation': isNavigation,
      'AgeNavigation': isNavigation,
      'AssetBundle': isGeneric,
      'default': function _default() {
        return "";
      }
    };
    return (modelPrefixes[moduleType] || modelPrefixes['default'])();
  }
});