enifed('ember-views/lib/views/states', ['exports', 'ember-utils', 'ember-views/lib/views/states/default', 'ember-views/lib/views/states/pre_render', 'ember-views/lib/views/states/has_element', 'ember-views/lib/views/states/in_dom', 'ember-views/lib/views/states/destroying'], function (exports, _emberUtils, _default2, _pre_render, _has_element, _in_dom, _destroying) {
  'use strict';

  exports.states = undefined;
  exports.cloneStates = function (from) {
    var into = {};

    into._default = {};
    into.preRender = Object.create(into._default);
    into.destroying = Object.create(into._default);
    into.hasElement = Object.create(into._default);
    into.inDOM = Object.create(into.hasElement);

    for (var stateName in from) {
      if (!from.hasOwnProperty(stateName)) {
        continue;
      }
      (0, _emberUtils.assign)(into[stateName], from[stateName]);
    }

    return into;
  }

  /*
    Describe how the specified actions should behave in the various
    states that a view can exist in. Possible states:
  
    * preRender: when a view is first instantiated, and after its
      element was destroyed, it is in the preRender state
    * hasElement: the DOM representation of the view is created,
      and is ready to be inserted
    * inDOM: once a view has been inserted into the DOM it is in
      the inDOM state. A view spends the vast majority of its
      existence in this state.
    * destroyed: once a view has been destroyed (using the destroy
      method), it is in this state. No further actions can be invoked
      on a destroyed view.
  */
  ;
  exports.states = {
    _default: _default2.default,
    preRender: _pre_render.default,
    inDOM: _in_dom.default,
    hasElement: _has_element.default,
    destroying: _destroying.default
  };
});