define("ocularium-frontend-core/services/monitoring/error", ["exports", "ocularium-frontend-core/lib/constants/ocularErrors"], function (_exports, _ocularErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    error: null,
    isAlertVisible: false,
    setError: function setError(errorName) {
      this.set('error', _ocularErrors.default[errorName.code]);
    },
    resetError: function resetError() {
      this.set('isAlertVisible', false);
      this.set('error', null);
    },
    showAlert: function showAlert() {
      this.set('isAlertVisible', true);
    },
    hideAlert: function hideAlert() {
      this.set('isAlertVisible', false);
    }
  });

  _exports.default = _default;
});