define("ember-popper/templates/components/ember-popper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LqZEgKRk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,[\"renderInPlace\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[11,\"id\",[20,\"id\"]],[11,\"class\",[20,\"class\"]],[11,\"hidden\",[20,\"hidden\"]],[11,\"role\",[20,\"ariaRole\"]],[8],[0,\"\\n    \"],[13,1,[[26,\"hash\",null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[26,\"action\",[[21,0,[]],\"disableEventListeners\"],null],[26,\"action\",[[21,0,[]],\"enableEventListeners\"],null],[26,\"action\",[[21,0,[]],\"scheduleUpdate\"],null],[26,\"action\",[[21,0,[]],\"update\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"in-element\",[[22,[\"_popperContainer\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[6,\"div\"],[11,\"id\",[20,\"id\"]],[11,\"class\",[20,\"class\"]],[11,\"hidden\",[20,\"hidden\"]],[11,\"role\",[20,\"ariaRole\"]],[8],[0,\"\\n    \"],[13,1,[[26,\"hash\",null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[26,\"action\",[[21,0,[]],\"disableEventListeners\"],null],[26,\"action\",[[21,0,[]],\"enableEventListeners\"],null],[26,\"action\",[[21,0,[]],\"scheduleUpdate\"],null],[26,\"action\",[[21,0,[]],\"update\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-popper/templates/components/ember-popper.hbs"
    }
  });

  _exports.default = _default;
});