define("ocularium-frontend-core/helpers/asset-bundle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service('intl'),
    store: Ember.inject.service('store'),
    assetService: Ember.inject.service('asset-bundle'),
    assetPath: null,
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          _ref2$ = _ref2[1],
          lang = _ref2$ === void 0 ? null : _ref2$;

      var language = lang || this.get('intl').get('locale')[0];
      var translations = this.get('assetService').getTranslations();

      if (translations[key]) {
        return translations[key][language];
      } else {
        console.warn('It seems that our painting of "' + key + '" from our "' + language + '" collection is missing.');
        return 'assets/icons/not_found_asset.svg';
      }
    },
    _recomputeOnLocaleChange: Ember.observer('intl.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});