define("ocularium-frontend-core/services/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    user: null,
    tempUser: null,
    isLoggedIn: false,
    redirectId: 0,
    showAlertMessage: false,
    setUser: function setUser(user) {
      this.set('user', user);
      this.set('isLoggedIn', true);
    },
    getUser: function getUser() {
      return this.get('user');
    },
    getUserAdditionalInfo: function getUserAdditionalInfo() {
      return this.getUser().get('additionalInformation');
    },
    setTempUser: function setTempUser(user) {
      this.set('tempUser', user);
    },
    getTempUser: function getTempUser() {
      return this.get('tempUser');
    },
    clearUser: function clearUser() {
      this.set('user', null);
      this.set('isLoggedIn', false);
    },
    clearTempUser: function clearTempUser() {
      this.set('tempUser', null);
    }
  });

  _exports.default = _default;
});