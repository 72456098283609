define("ember-bootstrap/templates/components/bs-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a+ft82fs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[26,\"unbound\",[[22,[\"_parentFinder\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[22,[\"inDom\"]]],null,{\"statements\":[[4,\"component\",[[22,[\"elementComponent\"]]],[[\"id\",\"parent\",\"placement\",\"fade\",\"showHelp\",\"title\",\"class\",\"renderInPlace\",\"popperTarget\",\"autoPlacement\",\"viewportElement\",\"viewportPadding\"],[[22,[\"overlayId\"]],[21,0,[]],[22,[\"placement\"]],[22,[\"fade\"]],[22,[\"showHelp\"]],[22,[\"title\"]],[22,[\"class\"]],[22,[\"_renderInPlace\"]],[22,[\"triggerTargetElement\"]],[22,[\"autoPlacement\"]],[22,[\"viewportElement\"]],[22,[\"viewportPadding\"]]]],{\"statements\":[[0,\"    \"],[13,1,[[26,\"hash\",null,[[\"close\"],[[26,\"action\",[[21,0,[]],\"close\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs"
    }
  });

  _exports.default = _default;
});