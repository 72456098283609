define("ocularium-frontend-core/lib/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addCustomClassName = addCustomClassName;

  function addCustomClassName(classBase, value) {
    var target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var element = target ? document.getElementsByClassName(target)[0] : document.getElementsByTagName('body')[0];

    if (element) {
      if (element.classList.length > 0) {
        //for loop IE support
        for (var i = 0; i < element.classList.length; i++) {
          if (element.classList[i].indexOf(classBase) > -1) {
            element.classList.remove(element.classList[i]);
          }
        }
      }

      if (!element.classList.contains(classBase + "--" + value) && value) {
        element.classList.add(classBase + "--" + value);
      }
    }
  }
});