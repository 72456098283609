define("ocularium-frontend-core/services/boot", ["exports", "ocularium-frontend-core/mixins/generic-service-mixin", "ocularium-frontend-core/lib/data/requests", "ocularium-frontend-core/lib/constants/ocularErrors", "jquery"], function (_exports, _genericServiceMixin, _requests, _ocularErrors, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_genericServiceMixin.default, {
    dataService: Ember.inject.service('data'),
    scenarioService: Ember.inject.service('scenario'),
    themeService: Ember.inject.service('themes'),
    crossbarService: Ember.inject.service('io/crossbar'),
    bugsnagService: Ember.inject.service('monitoring/bugsnag'),
    isBooting: false,
    isOnline: false,
    initCacheWarmer: function initCacheWarmer(deviceId) {
      var _this = this;

      var isOnline;
      var apiConfig = window["ocularium"]["api"];
      console.log('-- checking connection to backend --');
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: apiConfig['host'],
          error: function error() {
            isOnline = false;
            console.warn("-- Backend unreachable --");
            return _this.onDataRequestsDone(isOnline, deviceId).then(function () {
              resolve(true);
            }).catch(function (e) {
              reject(e);
            });
          },
          success: function success() {
            isOnline = true; // online, no token needed

            if (!apiConfig['clientId'] && !apiConfig['clientSecret']) {
              _this.executeApiRequests(isOnline, deviceId).then(function () {
                resolve(true);
              }).catch(function (e) {
                reject(e);
              });
            } //online, token needed


            _this.get('dataService').getAccessToken().then(function () {
              return _this.executeApiRequests(isOnline, deviceId).then(function () {
                resolve(true);
              }).catch(function (e) {
                reject(e);
              });
            }).catch(function (e) {
              reject(e);
            });
          }
        });
      });
    },
    executeApiRequests: function executeApiRequests(isOnline, deviceId) {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        return _this2.get('dataService').execute((0, _requests.createRequest)("devices/" + deviceId, "device", deviceId)).then(function (result) {
          if (result['name'] === 'OculariumError') {
            reject(result);
          }

          return _this2.onDataRequestsDone(isOnline, deviceId).then(function (d) {
            resolve(d);
          }).catch(function (e) {
            reject(e);
          });
        }).catch(function () {
          // error during API requests
          return _this2.onDataRequestsDone(false, deviceId).then(function () {
            resolve(true);
          }).catch(function (e) {
            reject(e);
          });
        });
      });
    },
    onDataRequestsDone: function onDataRequestsDone(isOnline, deviceId) {
      var _this3 = this;

      if (!isOnline) {
        console.warn("-- offline: use cached storage --");
      } //check crossbar


      var config = window['ocularium']['crossbar'];
      this.set('crossbarActive', config['enabled']);

      if (!config['enabled']) {
        console.log('-- crossbar disabled by ocularium config --');
        return this.get('scenarioService').fetchActiveScenario(deviceId).then(function () {
          return _this3.setupScenarioData(deviceId).then(function () {
            return _this3.get('scenarioService').initTimeline();
          }).catch(function (e) {
            throw e;
          });
        }).catch(function (e) {
          throw e;
        });
      }

      return this.setupCrossbar(config).then(function (session) {
        return _this3.get('scenarioService').fetchActiveScenario(deviceId).then(function () {
          return _this3.setupScenarioData(deviceId).then(function () {
            return _this3.get('crossbarService').setupProcessors(session).then(function () {
              return _this3.get('scenarioService').initTimeline();
            }).catch(function (e) {
              throw e;
            });
          }).catch(function (e) {
            throw e;
          });
        }).catch(function (e) {
          throw e;
        });
      }).catch(function (e) {
        throw e;
      });
    },
    setupScenarioData: function setupScenarioData(deviceId) {
      console.log("-- fetching theme and label data --");
      return Ember.RSVP.all([this.get('themeService').getActiveThemeData(deviceId), this.getLabels(deviceId, 'global')]).then(function (d) {
        console.warn("-- all theme and label data loaded --");
        return d;
      }).catch(function (e) {
        throw e;
      });
    },
    setupCrossbar: function setupCrossbar(config) {
      console.log('-- setting up crossbar --');
      var crossbarService = this.get('crossbarService');
      return crossbarService.setupAutobahn(config).then(function (session) {
        return session;
      }).catch(function (reason) {
        if (reason === _ocularErrors.default.CROSSBAR_NO_REALM) {
          throw reason;
        }

        return reason;
      });
    },
    setupBugsnag: function setupBugsnag(env) {
      console.log('-- setting up bugsnag --');
      return this.get('bugsnagService').initialize(env);
    },

    /* */
    handleError: function handleError(error, reject) {
      console.error("-- something went wrong with the requests --");

      if (error['responseJSON'] && error['responseJSON']['message'] === "Device Not Found") {
        reject(_ocularErrors.default.DEVICE_NOT_FOUND);
      } else if (error['status'] === 401) {
        reject(_ocularErrors.default.UNAUTHORIZED_REQUEST);
      } else {
        reject(error);
      }
    }
  });

  _exports.default = _default;
});