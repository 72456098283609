define("ember-bootstrap/templates/components/bs-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mmrHGO3w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[22,[\"hidden\"]]],null,{\"statements\":[[4,\"if\",[[22,[\"dismissible\"]]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[10,\"class\",\"close\"],[10,\"aria-label\",\"Close\"],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"dismiss\"]],[8],[0,\"\\n      \"],[6,\"span\"],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[13,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-alert.hbs"
    }
  });

  _exports.default = _default;
});