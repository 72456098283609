define("ocularium-frontend-core/mixins/generic-service-mixin", ["exports", "ocularium-frontend-core/lib/data/labels/defaultModuleLabels", "ocularium-frontend-core/lib/style", "ocularium-shared/lib/processors/moduleCommandBus"], function (_exports, _defaultModuleLabels, _style, _moduleCommandBus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service('store'),
    intl: Ember.inject.service('intl'),
    labels: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('labels', []);
    },
    getLabels: function getLabels(id, type) {
      var _this = this;

      return this.get('store').find(type.toLowerCase() + '-labels', id).then(function (result) {
        var labelData = result.get('labels');

        _this.setLocales(Object.keys(labelData)[0]);

        _this.validateLabels(labelData, type);

        _this.setLabels(labelData);

        _this._addTranslations(labelData);

        return labelData;
      });
    },
    setLocales: function setLocales(locale) {
      var activeLocale = this.get('intl.locale');

      if (this.get('intl.LANGUAGES').length < 1) {
        this.get('intl').setLocale(locale);
      } else {
        this.get('intl').setLocale(activeLocale);
      }
    },
    setLabels: function setLabels(data) {
      this.set('labels', data);
    },
    validateLabels: function validateLabels(labels, type) {
      var defaultLocale = this.get('intl').get('locale')[0];

      if (_defaultModuleLabels.labels[type]) {
        var typeLabels = _defaultModuleLabels.labels[type];
        typeLabels.forEach(function (key) {
          if (type === 'global') {
            if (!(labels && labels[defaultLocale][key])) {
              labels[defaultLocale][key] = "Translation is missing :(";
              console.warn(String(type) + '-labels service did not find a specific entry for: ' + String(key));
            }
          } else {
            if (!(labels && labels[defaultLocale] && labels[defaultLocale][type])) {
              labels[defaultLocale][type].push({
                key: "Translation is missing :("
              });
              console.warn(String(type) + '-labels service did not find a specific entry for: ' + String(key));
            }
          }
        });
      }
    },
    _addTranslations: function _addTranslations(labels) {
      var intl = this.get('intl');
      Object.keys(labels).forEach(function (locale) {
        intl.addTranslations(locale, labels[locale]);
      });
    },

    /* */
    getModuleData: function getModuleData(id, type) {
      var _this2 = this;

      Ember.run.later(function () {
        (0, _style.addCustomClassName)('module', id, 'page');
        (0, _style.addCustomClassName)('module', id, 'module');
      }, 0);
      return new Promise(function (resolve, reject) {
        _this2.get('store').findRecord(type.toLowerCase() + '-settings', id).then(function (result) {
          resolve(result);
        }).catch(function (reason) {
          reject(reason);
        });
      });
    },

    /*Module CommandBus*/
    crossbarService: Ember.inject.service('io/crossbar'),
    createModuleCommandBus: function createModuleCommandBus(moduleId) {
      var allowRefresh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var commandBus = new _moduleCommandBus.default(window.ocularium.deviceId, moduleId, window.ocularium.deviceRole, this.get('crossbarService').publishCrossbarMessage);

      if (this.get('crossbarService') === null || this.get('crossbarService').get('messageProcessor') === null || this.get('crossbarService').get('messageProcessor').processors === null) {
        if (allowRefresh) {
          window.location.reload();
        }
      } else {
        this.get('crossbarService').get('messageProcessor').processors['ModuleCommandBus'] = commandBus;
        return commandBus;
      }
    },
    createModuleCommandBusPromise: function createModuleCommandBusPromise(moduleId) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          var commandBus = new _moduleCommandBus.default(window.ocularium.deviceId, moduleId, window.ocularium.deviceRole, _this3.get('crossbarService').publishCrossbarMessage);
          _this3.get('crossbarService').get('messageProcessor').processors["ModuleCommandBus"] = commandBus;
          resolve(commandBus);
        } catch (e) {
          reject(e);
        }
      });
    }
  });

  _exports.default = _default;
});