define("ember-bootstrap/templates/components/bs-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gTdLOwMr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[13,1,[[26,\"hash\",null,[[\"title\",\"close\"],[[26,\"component\",[[22,[\"titleComponent\"]]],null],[26,\"component\",[[22,[\"closeComponent\"]]],[[\"onClick\"],[[26,\"action\",[[21,0,[]],[22,[\"onClose\"]]],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1]],null,{\"statements\":[[0,\"    \"],[13,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[22,[\"titleComponent\"]]],null,{\"statements\":[[1,[20,\"title\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[22,[\"closeButton\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"component\",[[22,[\"closeComponent\"]]],[[\"onClick\"],[[26,\"action\",[[21,0,[]],[22,[\"onClose\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/header.hbs"
    }
  });

  _exports.default = _default;
});