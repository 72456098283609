enifed('ember-extension-support/lib/data_adapter', ['exports', 'ember-utils', 'ember-metal', 'ember-runtime'], function (exports, _emberUtils, _emberMetal, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    init: function () {
      this._super.apply(this, arguments);
      this.releaseMethods = (0, _emberRuntime.A)();
    },

    /**
      The container-debug-adapter which is used
      to list all models.
       @property containerDebugAdapter
      @default undefined
      @since 1.5.0
      @public
    **/
    containerDebugAdapter: undefined,

    /**
      The number of attributes to send
      as columns. (Enough to make the record
      identifiable).
       @private
      @property attributeLimit
      @default 3
      @since 1.3.0
    */
    attributeLimit: 3,

    /**
       Ember Data > v1.0.0-beta.18
       requires string model names to be passed
       around instead of the actual factories.
        This is a stamp for the Ember Inspector
       to differentiate between the versions
       to be able to support older versions too.
        @public
       @property acceptsModelName
     */
    acceptsModelName: true,

    /**
      Stores all methods that clear observers.
      These methods will be called on destruction.
       @private
      @property releaseMethods
      @since 1.3.0
    */
    releaseMethods: (0, _emberRuntime.A)(),

    /**
      Specifies how records can be filtered.
      Records returned will need to have a `filterValues`
      property with a key for every name in the returned array.
       @public
      @method getFilters
      @return {Array} List of objects defining filters.
       The object should have a `name` and `desc` property.
    */
    getFilters: function () {
      return (0, _emberRuntime.A)();
    },

    /**
      Fetch the model types and observe them for changes.
       @public
      @method watchModelTypes
       @param {Function} typesAdded Callback to call to add types.
      Takes an array of objects containing wrapped types (returned from `wrapModelType`).
       @param {Function} typesUpdated Callback to call when a type has changed.
      Takes an array of objects containing wrapped types.
       @return {Function} Method to call to remove all observers
    */
    watchModelTypes: function (typesAdded, typesUpdated) {
      var _this = this;

      var modelTypes = this.getModelTypes();
      var releaseMethods = (0, _emberRuntime.A)();
      var typesToSend = void 0;

      typesToSend = modelTypes.map(function (type) {
        var klass = type.klass;
        var wrapped = _this.wrapModelType(klass, type.name);
        releaseMethods.push(_this.observeModelType(type.name, typesUpdated));
        return wrapped;
      });

      typesAdded(typesToSend);

      var release = function () {
        releaseMethods.forEach(function (fn) {
          return fn();
        });
        _this.releaseMethods.removeObject(release);
      };
      this.releaseMethods.pushObject(release);
      return release;
    },
    _nameToClass: function (type) {
      var owner, Factory;

      if (typeof type === 'string') {
        owner = (0, _emberUtils.getOwner)(this);
        Factory = owner.factoryFor('model:' + type);

        type = Factory && Factory.class;
      }
      return type;
    },

    /**
      Fetch the records of a given type and observe them for changes.
       @public
      @method watchRecords
       @param {String} modelName The model name.
       @param {Function} recordsAdded Callback to call to add records.
      Takes an array of objects containing wrapped records.
      The object should have the following properties:
        columnValues: {Object} The key and value of a table cell.
        object: {Object} The actual record object.
       @param {Function} recordsUpdated Callback to call when a record has changed.
      Takes an array of objects containing wrapped records.
       @param {Function} recordsRemoved Callback to call when a record has removed.
      Takes the following parameters:
        index: The array index where the records were removed.
        count: The number of records removed.
       @return {Function} Method to call to remove all observers.
    */
    watchRecords: function (modelName, recordsAdded, recordsUpdated, recordsRemoved) {
      var _this2 = this;

      var releaseMethods = (0, _emberRuntime.A)();
      var klass = this._nameToClass(modelName);
      var records = this.getRecords(klass, modelName);
      var release = void 0;

      function recordUpdated(updatedRecord) {
        recordsUpdated([updatedRecord]);
      }

      var recordsToSend = records.map(function (record) {
        releaseMethods.push(_this2.observeRecord(record, recordUpdated));
        return _this2.wrapRecord(record);
      });

      var observer = {
        didChange: function (array, idx, removedCount, addedCount) {
          var i, record, wrapped;

          for (i = idx; i < idx + addedCount; i++) {
            record = (0, _emberMetal.objectAt)(array, i);
            wrapped = _this2.wrapRecord(record);

            releaseMethods.push(_this2.observeRecord(record, recordUpdated));
            recordsAdded([wrapped]);
          }

          if (removedCount) {
            recordsRemoved(idx, removedCount);
          }
        },
        willChange: function () {
          return this;
        }
      };
      (0, _emberMetal.addArrayObserver)(records, this, observer);

      release = function () {
        releaseMethods.forEach(function (fn) {
          return fn();
        });
        (0, _emberMetal.removeArrayObserver)(records, _this2, observer);
        _this2.releaseMethods.removeObject(release);
      };

      recordsAdded(recordsToSend);

      this.releaseMethods.pushObject(release);
      return release;
    },

    /**
      Clear all observers before destruction
      @private
      @method willDestroy
    */
    willDestroy: function () {
      this._super.apply(this, arguments);
      this.releaseMethods.forEach(function (fn) {
        return fn();
      });
    },

    /**
      Detect whether a class is a model.
       Test that against the model class
      of your persistence library.
       @private
      @method detect
      @return boolean Whether the class is a model class or not.
    */
    detect: function () {
      return false;
    },

    /**
      Get the columns for a given model type.
       @private
      @method columnsForType
      @return {Array} An array of columns of the following format:
       name: {String} The name of the column.
       desc: {String} Humanized description (what would show in a table column name).
    */
    columnsForType: function () {
      return (0, _emberRuntime.A)();
    },

    /**
      Adds observers to a model type class.
       @private
      @method observeModelType
      @param {String} modelName The model type name.
      @param {Function} typesUpdated Called when a type is modified.
      @return {Function} The function to call to remove observers.
    */

    observeModelType: function (modelName, typesUpdated) {
      var _this3 = this;

      var klass = this._nameToClass(modelName);
      var records = this.getRecords(klass, modelName);

      function onChange() {
        typesUpdated([this.wrapModelType(klass, modelName)]);
      }

      var observer = {
        didChange: function (array, idx, removedCount, addedCount) {
          // Only re-fetch records if the record count changed
          // (which is all we care about as far as model types are concerned).
          if (removedCount > 0 || addedCount > 0) {
            (0, _emberMetal.scheduleOnce)('actions', this, onChange);
          }
        },
        willChange: function () {
          return this;
        }
      };

      (0, _emberMetal.addArrayObserver)(records, this, observer);

      return function () {
        return (0, _emberMetal.removeArrayObserver)(records, _this3, observer);
      };
    },

    /**
      Wraps a given model type and observes changes to it.
       @private
      @method wrapModelType
      @param {Class} klass A model class.
      @param {String} modelName Name of the class.
      @return {Object} Contains the wrapped type and the function to remove observers
      Format:
        type: {Object} The wrapped type.
          The wrapped type has the following format:
            name: {String} The name of the type.
            count: {Integer} The number of records available.
            columns: {Columns} An array of columns to describe the record.
            object: {Class} The actual Model type class.
        release: {Function} The function to remove observers.
    */
    wrapModelType: function (klass, name) {
      var records = this.getRecords(klass, name);
      var typeToSend = void 0;

      typeToSend = {
        name: name,
        count: (0, _emberMetal.get)(records, 'length'),
        columns: this.columnsForType(klass),
        object: klass
      };

      return typeToSend;
    },

    /**
      Fetches all models defined in the application.
       @private
      @method getModelTypes
      @return {Array} Array of model types.
    */
    getModelTypes: function () {
      var _this4 = this;

      var containerDebugAdapter = this.get('containerDebugAdapter');
      var types = void 0;

      if (containerDebugAdapter.canCatalogEntriesByType('model')) {
        types = containerDebugAdapter.catalogEntriesByType('model');
      } else {
        types = this._getObjectsOnNamespaces();
      }

      // New adapters return strings instead of classes.
      types = (0, _emberRuntime.A)(types).map(function (name) {
        return {
          klass: _this4._nameToClass(name),
          name: name
        };
      });
      types = (0, _emberRuntime.A)(types).filter(function (type) {
        return _this4.detect(type.klass);
      });

      return (0, _emberRuntime.A)(types);
    },

    /**
      Loops over all namespaces and all objects
      attached to them.
       @private
      @method _getObjectsOnNamespaces
      @return {Array} Array of model type strings.
    */
    _getObjectsOnNamespaces: function () {
      var _this5 = this;

      var namespaces = (0, _emberRuntime.A)(_emberRuntime.Namespace.NAMESPACES);
      var types = (0, _emberRuntime.A)();

      namespaces.forEach(function (namespace) {
        var name;

        for (var key in namespace) {
          if (!namespace.hasOwnProperty(key)) {
            continue;
          }
          // Even though we will filter again in `getModelTypes`,
          // we should not call `lookupFactory` on non-models
          if (!_this5.detect(namespace[key])) {
            continue;
          }
          name = _emberRuntime.String.dasherize(key);

          types.push(name);
        }
      });
      return types;
    },

    /**
      Fetches all loaded records for a given type.
       @private
      @method getRecords
      @return {Array} An array of records.
       This array will be observed for changes,
       so it should update when new records are added/removed.
    */
    getRecords: function () {
      return (0, _emberRuntime.A)();
    },

    /**
      Wraps a record and observers changes to it.
       @private
      @method wrapRecord
      @param {Object} record The record instance.
      @return {Object} The wrapped record. Format:
      columnValues: {Array}
      searchKeywords: {Array}
    */
    wrapRecord: function (record) {
      var recordToSend = { object: record };

      recordToSend.columnValues = this.getRecordColumnValues(record);
      recordToSend.searchKeywords = this.getRecordKeywords(record);
      recordToSend.filterValues = this.getRecordFilterValues(record);
      recordToSend.color = this.getRecordColor(record);

      return recordToSend;
    },

    /**
      Gets the values for each column.
       @private
      @method getRecordColumnValues
      @return {Object} Keys should match column names defined
      by the model type.
    */
    getRecordColumnValues: function () {
      return {};
    },

    /**
      Returns keywords to match when searching records.
       @private
      @method getRecordKeywords
      @return {Array} Relevant keywords for search.
    */
    getRecordKeywords: function () {
      return (0, _emberRuntime.A)();
    },

    /**
      Returns the values of filters defined by `getFilters`.
       @private
      @method getRecordFilterValues
      @param {Object} record The record instance.
      @return {Object} The filter values.
    */
    getRecordFilterValues: function () {
      return {};
    },

    /**
      Each record can have a color that represents its state.
       @private
      @method getRecordColor
      @param {Object} record The record instance
      @return {String} The records color.
        Possible options: black, red, blue, green.
    */
    getRecordColor: function () {
      return null;
    },

    /**
      Observes all relevant properties and re-sends the wrapped record
      when a change occurs.
       @private
      @method observerRecord
      @return {Function} The function to call to remove all observers.
    */
    observeRecord: function () {
      return function () {};
    }
  });
});