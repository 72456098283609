enifed('ember-runtime/lib/mixins/-proxy', ['exports', '@glimmer/reference', 'ember-metal', 'ember-utils', 'ember-debug', 'ember-runtime/lib/computed/computed_macros'], function (exports, _reference, _emberMetal, _emberUtils, _emberDebug, _computed_macros) {
  'use strict';

  exports.contentFor = contentFor;

  function contentPropertyDidChange(content, contentKey) {
    var key = contentKey.slice(8); // remove "content."
    if (key in this) {
      return;
    } // if shadowed in proxy
    (0, _emberMetal.notifyPropertyChange)(this, key);
  } /**
    @module ember
    */

  function contentFor(proxy, m) {
    var content = (0, _emberMetal.get)(proxy, 'content');
    var tag = (m === undefined ? (0, _emberMetal.meta)(proxy) : m).readableTag();
    if (tag !== undefined) {
      tag.inner.second.inner.update((0, _emberMetal.tagFor)(content));
    }
    return content;
  }

  /**
    `Ember.ProxyMixin` forwards all properties not defined by the proxy itself
    to a proxied `content` object.  See ObjectProxy for more details.
  
    @class ProxyMixin
    @namespace Ember
    @private
  */
  exports.default = _emberMetal.Mixin.create({
    /**
      The object whose properties will be forwarded.
       @property content
      @type EmberObject
      @default null
      @private
    */
    content: null,

    init: function () {
      this._super.apply(this, arguments);
      (0, _emberUtils.setProxy)(this);
      var m = (0, _emberMetal.meta)(this);
      m.writableTag(function () {
        return (0, _reference.combine)([_reference.DirtyableTag.create(), _reference.UpdatableTag.create(_reference.CONSTANT_TAG)]);
      });
    },

    isTruthy: (0, _computed_macros.bool)('content'),

    willWatchProperty: function (key) {
      (0, _emberMetal.addObserver)(this, 'content.' + key, null, contentPropertyDidChange);
    },
    didUnwatchProperty: function (key) {
      (0, _emberMetal.removeObserver)(this, 'content.' + key, null, contentPropertyDidChange);
    },
    unknownProperty: function (key) {
      var content = contentFor(this);
      if (content) {
        return (0, _emberMetal.get)(content, key);
      }
    },
    setUnknownProperty: function (key, value) {
      var m = (0, _emberMetal.meta)(this);

      if (m.proto === this) {
        // if marked as prototype then just defineProperty
        // rather than delegate
        (0, _emberMetal.defineProperty)(this, key, null, value);
        return value;
      }

      var content = contentFor(this, m);

      false && !content && (0, _emberDebug.assert)('Cannot delegate set(\'' + key + '\', ' + value + ') to the \'content\' property of object proxy ' + this + ': its \'content\' is undefined.', content);

      return (0, _emberMetal.set)(content, key, value);
    }
  });
});