define("ocularium-frontend-core/services/scenario", ["exports", "ocularium-shared/lib/scenarioManager", "ocularium-frontend-core/lib/constants/ocularErrors"], function (_exports, _scenarioManager, _ocularErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    bugsnagService: Ember.inject.service('monitoring/bugsnag'),
    intl: Ember.inject.service('intl'),
    router: Ember.inject.service('router'),
    activeScenario: null,
    scenarioManager: null,
    loadedApplication: null,
    loaded: false,
    isApplicationRunning: false,
    queryParamLanguage: null,
    // language set by queryParam

    /* */
    setActiveApplication: function setActiveApplication(app) {
      this.set('activeApplication', app);
    },
    getActiveApplication: function getActiveApplication() {
      return this.get('activeApplication');
    },
    setScenario: function setScenario(scenario) {
      this.set('activeScenario', scenario);
    },
    getScenario: function getScenario() {
      return this.get('activeScenario');
    },

    /* */
    fetchActiveScenario: function fetchActiveScenario(deviceId) {
      var _this = this;

      console.log("-- fetching active scenario --");
      var scenarioManager = new _scenarioManager.default();
      this.set('scenarioManager', scenarioManager);
      return new Promise(function (resolve, reject) {
        _this.getDeviceData(deviceId).then(function (deviceData) {
          return _this.get('store').findRecord('scenario-settings', deviceData.get('activeScenarioId')).then(function (result) {
            return Ember.RSVP.all([_this.setScenario(result), _this.getScenarioLanguages(result.get('deviceId'))]).then(function (result) {
              resolve(result);
            }).catch(function (reason) {
              reject(reason);
            });
          }).catch(function (reason) {
            reject(reason);
          });
        }).catch(function (reason) {
          reject(reason);
        });
      });
    },
    getDeviceData: function getDeviceData(deviceId) {
      return this.get('store').findRecord('device', deviceId).then(function (deviceData) {
        return deviceData;
      }).catch(function (reason) {
        throw reason;
      });
    },

    /* */
    initTimeline: function initTimeline() {
      var _this2 = this;

      var scenario = this.get('activeScenario');
      var entries = scenario['timeline'];

      if (!this.checkScenarioDuration(entries)) {
        throw _ocularErrors.default.SCENARIO_LENGTH;
      }

      this.set('entries', entries);
      var scenarioManager = this.get('scenarioManager');
      /** scenarioManager events - start
       *  - enableDelayOverlay
       *  - disableDelayOverlay
       *  - enableFadeOverlay
       *  - disableFadeOverlay
       *  - loadApplication
       *  - runApplication
       *  - unloadApplication
       *  - disableApplicationOverlay
       *  - enableApplicationOverlay
       *  - enableWaitingOverlay
       *  - disableWaitingOverlay
       * **/

      scenarioManager.on('loadApplication', function (e) {
        _this2.get('store').findRecord('application-settings', e['currentStep']['applicationId']).then(function (application) {
          _this2.set('loadedApplication', application);

          _this2.set('loaded', true);
        }).catch(function (reason) {
          console.error('-- error fetching application -- ', reason);
        });
      });
      scenarioManager.on('runApplication', function (e) {
        _this2.set('isApplicationRunning', true);

        if (!_this2.get('loadedApplication')) {
          console.error('loadedApplication is null/undefined');
        }

        _this2.setActiveApplication(_this2.get('loadedApplication'));

        _this2.addSettingsToBugsnag(e['currentStep'], _this2.get('loadedApplication'));
      });
      scenarioManager.on('unloadApplication', function () {
        console.warn('-- unloadApplication --');

        _this2.set('loaded', false);

        _this2.set('isApplicationRunning', false);
      });
      /* scenarioManager events - end*/

      scenarioManager.setSteps(entries);
      return entries;
    },

    /* get scenario-languages */
    getScenarioLanguages: function getScenarioLanguages(deviceId) {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        _this3.get('store').findRecord('scenario-languages', deviceId).then(function (result) {
          var languages = result.get('languages');

          _this3.set('intl.LANGUAGES', languages); //


          _this3.get('queryParamLanguage') ? _this3.get('intl').setLocale(_this3.get('queryParamLanguage')) : _this3.get('intl').setLocale(languages['0']['language']); //

          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },

    /* */
    addSettingsToBugsnag: function addSettingsToBugsnag(scenarioStep, application) {
      var bugsnagClient = this.get('bugsnagService.bugsnagClient');
      var activeScenario = this.get('activeScenario');
      bugsnagClient.metaData.settings = {
        scenario: activeScenario['scenarioId'],
        currentStep: scenarioStep,
        application: application['applicationId'] + ' - ' + application['name'],
        theme: 'not loaded'
      };
    },

    /* */
    checkScenarioDuration: function checkScenarioDuration(entries) {
      var valid = true;
      entries.forEach(function (entry) {
        if (entry["duration"] === "00:00:00" || entry["duration"] === undefined || entry["duration"] === null) {
          valid = false;
        }
      });
      return valid;
    },

    /* */
    navigateToIndex: function navigateToIndex() {
      this.get("router").transitionTo("index");
    }
  });

  _exports.default = _default;
});