enifed('ember-routing/lib/system/route', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-runtime', 'ember-routing/lib/system/generate_controller', 'ember-routing/lib/utils'], function (exports, _emberUtils, _emberMetal, _emberDebug, _emberRuntime, _generate_controller, _utils) {
  'use strict';

  exports.defaultSerialize = defaultSerialize;
  exports.hasDefaultSerialize = function (route) {
    return route.serialize === defaultSerialize;
  }

  /**
  @module @ember/routing
  */

  /**
    The `Route` class is used to define individual routes. Refer to
    the [routing guide](https://emberjs.com/guides/routing/) for documentation.
  
    @class Route
    @extends EmberObject
    @uses ActionHandler
    @uses Evented
    @since 1.0.0
    @public
  */
  ;

  function K() {
    return this;
  }

  function defaultSerialize(model, params) {
    if (params.length < 1 || !model) {
      return;
    }

    var object = {},
        name;
    if (params.length === 1) {
      name = params[0];


      if (name in model) {
        object[name] = (0, _emberMetal.get)(model, name);
      } else if (/_id$/.test(name)) {
        object[name] = (0, _emberMetal.get)(model, 'id');
      }
    } else {
      object = (0, _emberMetal.getProperties)(model, params);
    }

    return object;
  }

  var Route = _emberRuntime.Object.extend(_emberRuntime.ActionHandler, _emberRuntime.Evented, {
    /**
      Configuration hash for this route's queryParams. The possible
      configuration options and their defaults are as follows
      (assuming a query param whose controller property is `page`):
       ```javascript
      queryParams: {
        page: {
          // By default, controller query param properties don't
          // cause a full transition when they are changed, but
          // rather only cause the URL to update. Setting
          // `refreshModel` to true will cause an "in-place"
          // transition to occur, whereby the model hooks for
          // this route (and any child routes) will re-fire, allowing
          // you to reload models (e.g., from the server) using the
          // updated query param values.
          refreshModel: false,
           // By default, changes to controller query param properties
          // cause the URL to update via `pushState`, which means an
          // item will be added to the browser's history, allowing
          // you to use the back button to restore the app to the
          // previous state before the query param property was changed.
          // Setting `replace` to true will use `replaceState` (or its
          // hash location equivalent), which causes no browser history
          // item to be added. This options name and default value are
          // the same as the `link-to` helper's `replace` option.
          replace: false,
           // By default, the query param URL key is the same name as
          // the controller property name. Use `as` to specify a
          // different URL key.
          as: 'page'
        }
      }
      ```
       @property queryParams
      @for Route
      @type Object
      @since 1.6.0
      @public
    */
    queryParams: {},

    router: (0, _emberMetal.computed)('_router', function () {
      false && !false && (0, _emberDebug.deprecate)('Route#router is an intimate API that has been renamed to Route#_router. However you might want to consider using the router service', false, {
        id: 'ember-routing.route-router',
        until: '3.5.0',
        url: 'https://emberjs.com/deprecations/v3.x#toc_ember-routing-route-router'
      });

      return this._router;
    }),

    _setRouteName: function (name) {
      this.routeName = name;
      this.fullRouteName = getEngineRouteName((0, _emberUtils.getOwner)(this), name);
    },

    /**
      @private
       @property _qp
    */
    _qp: (0, _emberMetal.computed)(function () {
      var _this = this,
          controllerDefinedQueryParameterConfiguration,
          normalizedControllerQueryParameterConfiguration,
          desc,
          scope,
          parts,
          urlKey,
          defaultValue,
          type,
          defaultValueSerialized,
          scopedPropertyName,
          qp;

      var combinedQueryParameterConfiguration = void 0;

      var controllerName = this.controllerName || this.routeName;
      var owner = (0, _emberUtils.getOwner)(this);
      var controller = owner.lookup('controller:' + controllerName);
      var queryParameterConfiguraton = (0, _emberMetal.get)(this, 'queryParams');
      var hasRouterDefinedQueryParams = Object.keys(queryParameterConfiguraton).length > 0;

      if (controller) {
        // the developer has authored a controller class in their application for
        // this route find its query params and normalize their object shape them
        // merge in the query params for the route. As a mergedProperty,
        // Route#queryParams is always at least `{}`

        controllerDefinedQueryParameterConfiguration = (0, _emberMetal.get)(controller, 'queryParams') || {};
        normalizedControllerQueryParameterConfiguration = (0, _utils.normalizeControllerQueryParams)(controllerDefinedQueryParameterConfiguration);

        combinedQueryParameterConfiguration = mergeEachQueryParams(normalizedControllerQueryParameterConfiguration, queryParameterConfiguraton);
      } else if (hasRouterDefinedQueryParams) {
        // the developer has not defined a controller but *has* supplied route query params.
        // Generate a class for them so we can later insert default values
        controller = (0, _generate_controller.default)(owner, controllerName);
        combinedQueryParameterConfiguration = queryParameterConfiguraton;
      }

      var qps = [];
      var map = {};
      var propertyNames = [];

      for (var propName in combinedQueryParameterConfiguration) {
        if (!combinedQueryParameterConfiguration.hasOwnProperty(propName)) {
          continue;
        }

        // to support the dubious feature of using unknownProperty
        // on queryParams configuration
        if (propName === 'unknownProperty' || propName === '_super') {
          // possible todo: issue deprecation warning?
          continue;
        }

        desc = combinedQueryParameterConfiguration[propName];
        scope = desc.scope || 'model';
        parts = void 0;


        if (scope === 'controller') {
          parts = [];
        }

        urlKey = desc.as || this.serializeQueryParamKey(propName);
        defaultValue = (0, _emberMetal.get)(controller, propName);


        if (Array.isArray(defaultValue)) {
          defaultValue = (0, _emberRuntime.A)(defaultValue.slice());
        }

        type = desc.type || (0, _emberRuntime.typeOf)(defaultValue);
        defaultValueSerialized = this.serializeQueryParam(defaultValue, urlKey, type);
        scopedPropertyName = controllerName + ':' + propName;
        qp = {
          undecoratedDefaultValue: (0, _emberMetal.get)(controller, propName),
          defaultValue: defaultValue,
          serializedDefaultValue: defaultValueSerialized,
          serializedValue: defaultValueSerialized,

          type: type,
          urlKey: urlKey,
          prop: propName,
          scopedPropertyName: scopedPropertyName,
          controllerName: controllerName,
          route: this,
          parts: parts, // provided later when stashNames is called if 'model' scope
          values: null, // provided later when setup is called. no idea why.
          scope: scope
        };


        map[propName] = map[urlKey] = map[scopedPropertyName] = qp;
        qps.push(qp);
        propertyNames.push(propName);
      }

      return {
        qps: qps,
        map: map,
        propertyNames: propertyNames,
        states: {
          /*
            Called when a query parameter changes in the URL, this route cares
            about that query parameter, but the route is not currently
            in the active route hierarchy.
          */
          inactive: function (prop, value) {
            var qp = map[prop];
            _this._qpChanged(prop, value, qp);
          },
          /*
            Called when a query parameter changes in the URL, this route cares
            about that query parameter, and the route is currently
            in the active route hierarchy.
          */
          active: function (prop, value) {
            var qp = map[prop];
            _this._qpChanged(prop, value, qp);
            return _this._activeQPChanged(qp, value);
          },
          /*
            Called when a value of a query parameter this route handles changes in a controller
            and the route is currently in the active route hierarchy.
          */
          allowOverrides: function (prop, value) {
            var qp = map[prop];
            _this._qpChanged(prop, value, qp);
            return _this._updatingQPChanged(qp);
          }
        }
      };
    }),

    /**
      @private
       @property _names
    */
    _names: null,

    _stashNames: function (handlerInfo, dynamicParent) {
      if (this._names) {
        return;
      }
      var names = this._names = handlerInfo._names,
          a,
          i,
          qp;

      if (!names.length) {
        handlerInfo = dynamicParent;
        names = handlerInfo && handlerInfo._names || [];
      }

      var qps = (0, _emberMetal.get)(this, '_qp.qps');

      var namePaths = new Array(names.length);
      for (a = 0; a < names.length; ++a) {
        namePaths[a] = handlerInfo.name + '.' + names[a];
      }

      for (i = 0; i < qps.length; ++i) {
        qp = qps[i];

        if (qp.scope === 'model') {
          qp.parts = namePaths;
        }
      }
    },
    _activeQPChanged: function (qp, value) {
      this._router._activeQPChanged(qp.scopedPropertyName, value);
    },
    _updatingQPChanged: function (qp) {
      this._router._updatingQPChanged(qp.urlKey);
    },

    mergedProperties: ['queryParams'],

    paramsFor: function (name) {
      var _this2 = this;

      var route = (0, _emberUtils.getOwner)(this).lookup('route:' + name);

      if (!route) {
        return {};
      }

      var transition = this._router._routerMicrolib.activeTransition;
      var state = transition ? transition.state : this._router._routerMicrolib.state;

      var fullName = route.fullRouteName;
      var params = (0, _emberUtils.assign)({}, state.params[fullName]);
      var queryParams = getQueryParamsFor(route, state);

      return Object.keys(queryParams).reduce(function (params, key) {
        false && !!params[key] && (0, _emberDebug.assert)('The route \'' + _this2.routeName + '\' has both a dynamic segment and query param with name \'' + key + '\'. Please rename one to avoid collisions.', !params[key]);

        params[key] = queryParams[key];
        return params;
      }, params);
    },
    serializeQueryParamKey: function (controllerPropertyName) {
      return controllerPropertyName;
    },
    serializeQueryParam: function (value, urlKey, defaultValueType) {
      // urlKey isn't used here, but anyone overriding
      // can use it to provide serialization specific
      // to a certain query param.
      return this._router._serializeQueryParam(value, defaultValueType);
    },
    deserializeQueryParam: function (value, urlKey, defaultValueType) {
      // urlKey isn't used here, but anyone overriding
      // can use it to provide deserialization specific
      // to a certain query param.
      return this._router._deserializeQueryParam(value, defaultValueType);
    },
    _optionsForQueryParam: function (qp) {
      return (0, _emberMetal.get)(this, 'queryParams.' + qp.urlKey) || (0, _emberMetal.get)(this, 'queryParams.' + qp.prop) || {};
    },

    /**
      A hook you can use to reset controller values either when the model
      changes or the route is exiting.
       ```app/routes/articles.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        resetController(controller, isExiting, transition) {
          if (isExiting && transition.targetName !== 'error') {
            controller.set('page', 1);
          }
        }
      });
      ```
       @method resetController
      @param {Controller} controller instance
      @param {Boolean} isExiting
      @param {Object} transition
      @since 1.7.0
      @public
    */
    resetController: K,

    exit: function () {
      this.deactivate();
      this.trigger('deactivate');
      this.teardownViews();
    },
    _reset: function (isExiting, transition) {
      var controller = this.controller;
      controller._qpDelegate = (0, _emberMetal.get)(this, '_qp.states.inactive');

      this.resetController(controller, isExiting, transition);
    },
    enter: function () {
      this.connections = [];
      this.activate();
      this.trigger('activate');
    },

    /**
      The name of the template to use by default when rendering this routes
      template.
       ```app/routes/posts/list.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        templateName: 'posts/list'
      });
      ```
       ```app/routes/posts/index.js
      import PostsList from '../posts/list';
       export default PostsList.extend();
      ```
       ```app/routes/posts/archived.js
      import PostsList from '../posts/list';
       export default PostsList.extend();
      ```
       @property templateName
      @type String
      @default null
      @since 1.4.0
      @public
    */
    templateName: null,

    /**
      The name of the controller to associate with this route.
       By default, Ember will lookup a route's controller that matches the name
      of the route (i.e. `posts.new`). However,
      if you would like to define a specific controller to use, you can do so
      using this property.
       This is useful in many ways, as the controller specified will be:
       * passed to the `setupController` method.
      * used as the controller for the template being rendered by the route.
      * returned from a call to `controllerFor` for the route.
       @property controllerName
      @type String
      @default null
      @since 1.4.0
      @public
    */
    controllerName: null,

    /**
      The `willTransition` action is fired at the beginning of any
      attempted transition with a `Transition` object as the sole
      argument. This action can be used for aborting, redirecting,
      or decorating the transition from the currently active routes.
       A good example is preventing navigation when a form is
      half-filled out:
       ```app/routes/contact-form.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        actions: {
          willTransition(transition) {
            if (this.controller.get('userHasEnteredData')) {
              this.controller.displayNavigationConfirm();
              transition.abort();
            }
          }
        }
      });
      ```
       You can also redirect elsewhere by calling
      `this.transitionTo('elsewhere')` from within `willTransition`.
      Note that `willTransition` will not be fired for the
      redirecting `transitionTo`, since `willTransition` doesn't
      fire when there is already a transition underway. If you want
      subsequent `willTransition` actions to fire for the redirecting
      transition, you must first explicitly call
      `transition.abort()`.
       To allow the `willTransition` event to continue bubbling to the parent
      route, use `return true;`. When the `willTransition` method has a
      return value of `true` then the parent route's `willTransition` method
      will be fired, enabling "bubbling" behavior for the event.
       @event willTransition
      @param {Transition} transition
      @since 1.0.0
      @public
    */

    /**
      The `didTransition` action is fired after a transition has
      successfully been completed. This occurs after the normal model
      hooks (`beforeModel`, `model`, `afterModel`, `setupController`)
      have resolved. The `didTransition` action has no arguments,
      however, it can be useful for tracking page views or resetting
      state on the controller.
       ```app/routes/login.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        actions: {
          didTransition() {
            this.controller.get('errors.base').clear();
            return true; // Bubble the didTransition event
          }
        }
      });
      ```
       @event didTransition
      @since 1.2.0
      @public
    */

    /**
      The `loading` action is fired on the route when a route's `model`
      hook returns a promise that is not already resolved. The current
      `Transition` object is the first parameter and the route that
      triggered the loading event is the second parameter.
       ```app/routes/application.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        actions: {
          loading(transition, route) {
            let controller = this.controllerFor('foo');
            controller.set('currentlyLoading', true);
             transition.finally(function() {
              controller.set('currentlyLoading', false);
            });
          }
        }
      });
      ```
       @event loading
      @param {Transition} transition
      @param {Route} route The route that triggered the loading event
      @since 1.2.0
      @public
    */

    /**
      When attempting to transition into a route, any of the hooks
      may return a promise that rejects, at which point an `error`
      action will be fired on the partially-entered routes, allowing
      for per-route error handling logic, or shared error handling
      logic defined on a parent route.
       Here is an example of an error handler that will be invoked
      for rejected promises from the various hooks on the route,
      as well as any unhandled errors from child routes:
       ```app/routes/admin.js
      import { reject } from 'rsvp';
      import Route from '@ember/routing/route';
       export default Route.extend({
        beforeModel() {
          return reject('bad things!');
        },
         actions: {
          error(error, transition) {
            // Assuming we got here due to the error in `beforeModel`,
            // we can expect that error === "bad things!",
            // but a promise model rejecting would also
            // call this hook, as would any errors encountered
            // in `afterModel`.
             // The `error` hook is also provided the failed
            // `transition`, which can be stored and later
            // `.retry()`d if desired.
             this.transitionTo('login');
          }
        }
      });
      ```
       `error` actions that bubble up all the way to `ApplicationRoute`
      will fire a default error handler that logs the error. You can
      specify your own global default error handler by overriding the
      `error` handler on `ApplicationRoute`:
       ```app/routes/application.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        actions: {
          error(error, transition) {
            this.controllerFor('banner').displayError(error.message);
          }
        }
      });
      ```
      @event error
      @param {Error} error
      @param {Transition} transition
      @since 1.0.0
      @public
    */

    /**
      This event is triggered when the router enters the route. It is
      not executed when the model for the route changes.
       ```app/routes/application.js
      import { on } from '@ember/object/evented';
      import Route from '@ember/routing/route';
       export default Route.extend({
        collectAnalytics: on('activate', function(){
          collectAnalytics();
        })
      });
      ```
       @event activate
      @since 1.9.0
      @public
    */

    /**
      This event is triggered when the router completely exits this
      route. It is not executed when the model for the route changes.
       ```app/routes/index.js
      import { on } from '@ember/object/evented';
      import Route from '@ember/routing/route';
       export default Route.extend({
        trackPageLeaveAnalytics: on('deactivate', function(){
          trackPageLeaveAnalytics();
        })
      });
      ```
       @event deactivate
      @since 1.9.0
      @public
    */

    /**
      The controller associated with this route.
       Example
       ```app/routes/form.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        actions: {
          willTransition(transition) {
            if (this.controller.get('userHasEnteredData') &&
                !confirm('Are you sure you want to abandon progress?')) {
              transition.abort();
            } else {
              // Bubble the `willTransition` action so that
              // parent routes can decide whether or not to abort.
              return true;
            }
          }
        }
      });
      ```
       @property controller
      @type Controller
      @since 1.6.0
      @public
    */

    actions: {
      queryParamsDidChange: function (changed, totalPresent, removed) {
        var qpMap = (0, _emberMetal.get)(this, '_qp').map,
            i,
            qp;

        var totalChanged = Object.keys(changed).concat(Object.keys(removed));
        for (i = 0; i < totalChanged.length; ++i) {
          qp = qpMap[totalChanged[i]];

          if (qp && (0, _emberMetal.get)(this._optionsForQueryParam(qp), 'refreshModel') && this._router.currentState) {
            this.refresh();
            break;
          }
        }

        return true;
      },
      finalizeQueryParamChange: function (params, finalParams, transition) {
        if (this.fullRouteName !== 'application') {
          return true;
        }

        // Transition object is absent for intermediate transitions.
        if (!transition) {
          return;
        }

        var handlerInfos = transition.state.handlerInfos,
            i,
            qp,
            route,
            controller,
            presentKey,
            value,
            svalue,
            thisQueryParamChanged,
            options,
            replaceConfigValue,
            thisQueryParamHasDefaultValue;
        var router = this._router;
        var qpMeta = router._queryParamsFor(handlerInfos);
        var changes = router._qpUpdates;
        var replaceUrl = void 0;

        (0, _utils.stashParamNames)(router, handlerInfos);

        for (i = 0; i < qpMeta.qps.length; ++i) {
          qp = qpMeta.qps[i];
          route = qp.route;
          controller = route.controller;
          presentKey = qp.urlKey in params && qp.urlKey;

          // Do a reverse lookup to see if the changed query
          // param URL key corresponds to a QP property on
          // this controller.

          value = void 0, svalue = void 0;

          if (changes && qp.urlKey in changes) {
            // Value updated in/before setupController
            value = (0, _emberMetal.get)(controller, qp.prop);
            svalue = route.serializeQueryParam(value, qp.urlKey, qp.type);
          } else {
            if (presentKey) {
              svalue = params[presentKey];

              if (svalue !== undefined) {
                value = route.deserializeQueryParam(svalue, qp.urlKey, qp.type);
              }
            } else {
              // No QP provided; use default value.
              svalue = qp.serializedDefaultValue;
              value = copyDefaultValue(qp.defaultValue);
            }
          }

          controller._qpDelegate = (0, _emberMetal.get)(route, '_qp.states.inactive');

          thisQueryParamChanged = svalue !== qp.serializedValue;

          if (thisQueryParamChanged) {
            if (transition.queryParamsOnly && replaceUrl !== false) {
              options = route._optionsForQueryParam(qp);
              replaceConfigValue = (0, _emberMetal.get)(options, 'replace');

              if (replaceConfigValue) {
                replaceUrl = true;
              } else if (replaceConfigValue === false) {
                // Explicit pushState wins over any other replaceStates.
                replaceUrl = false;
              }
            }

            (0, _emberMetal.set)(controller, qp.prop, value);
          }

          // Stash current serialized value of controller.
          qp.serializedValue = svalue;

          thisQueryParamHasDefaultValue = qp.serializedDefaultValue === svalue;

          if (!thisQueryParamHasDefaultValue || transition._keepDefaultQueryParamValues) {
            finalParams.push({
              value: svalue,
              visible: true,
              key: presentKey || qp.urlKey
            });
          }
        }

        if (replaceUrl) {
          transition.method('replace');
        }

        qpMeta.qps.forEach(function (qp) {
          var routeQpMeta = (0, _emberMetal.get)(qp.route, '_qp');
          var finalizedController = qp.route.controller;
          finalizedController._qpDelegate = (0, _emberMetal.get)(routeQpMeta, 'states.active');
        });

        router._qpUpdates = null;
      }
    },

    /**
      This hook is executed when the router completely exits this route. It is
      not executed when the model for the route changes.
       @method deactivate
      @since 1.0.0
      @public
    */
    deactivate: K,

    /**
      This hook is executed when the router enters the route. It is not executed
      when the model for the route changes.
       @method activate
      @since 1.0.0
      @public
    */
    activate: K,

    transitionTo: function () /* name, context */{
      var _router;

      // eslint-disable-line no-unused-vars
      return (_router = this._router).transitionTo.apply(_router, (0, _utils.prefixRouteNameArg)(this, arguments));
    },
    intermediateTransitionTo: function () {
      var _router2;

      (_router2 = this._router).intermediateTransitionTo.apply(_router2, (0, _utils.prefixRouteNameArg)(this, arguments));
    },
    refresh: function () {
      return this._router._routerMicrolib.refresh(this);
    },
    replaceWith: function () {
      var _router3;

      return (_router3 = this._router).replaceWith.apply(_router3, (0, _utils.prefixRouteNameArg)(this, arguments));
    },
    send: function () {
      var _len, args, _key, _router4, name, action;

      for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      false && !(!this.isDestroying && !this.isDestroyed) && (0, _emberDebug.assert)('Attempted to call .send() with the action \'' + args[0] + '\' on the destroyed route \'' + this.routeName + '\'.', !this.isDestroying && !this.isDestroyed);

      if (this._router && this._router._routerMicrolib || !(0, _emberDebug.isTesting)()) {

        (_router4 = this._router).send.apply(_router4, args);
      } else {
        name = args.shift();
        action = this.actions[name];

        if (action) {
          return action.apply(this, args);
        }
      }
    },
    setup: function (context, transition) {
      var controller = void 0,
          propNames,
          cache,
          params,
          allParams,
          qpValues;

      var controllerName = this.controllerName || this.routeName;
      var definedController = this.controllerFor(controllerName, true);

      if (definedController) {
        controller = definedController;
      } else {
        controller = this.generateController(controllerName);
      }

      // Assign the route's controller so that it can more easily be
      // referenced in action handlers. Side effects. Side effects everywhere.
      if (!this.controller) {
        propNames = (0, _emberMetal.get)(this, '_qp.propertyNames');

        addQueryParamsObservers(controller, propNames);
        this.controller = controller;
      }

      var queryParams = (0, _emberMetal.get)(this, '_qp');

      var states = queryParams.states;

      controller._qpDelegate = states.allowOverrides;

      if (transition) {
        // Update the model dep values used to calculate cache keys.
        (0, _utils.stashParamNames)(this._router, transition.state.handlerInfos);

        cache = this._bucketCache;
        params = transition.params;
        allParams = queryParams.propertyNames;


        allParams.forEach(function (prop) {
          var aQp = queryParams.map[prop];
          aQp.values = params;

          var cacheKey = (0, _utils.calculateCacheKey)(aQp.route.fullRouteName, aQp.parts, aQp.values);
          var value = cache.lookup(cacheKey, prop, aQp.undecoratedDefaultValue);
          (0, _emberMetal.set)(controller, prop, value);
        });

        qpValues = getQueryParamsFor(this, transition.state);

        (0, _emberMetal.setProperties)(controller, qpValues);
      }

      this.setupController(controller, context, transition);

      if (this._environment.options.shouldRender) {
        this.renderTemplate(controller, context);
      }
    },
    _qpChanged: function (prop, value, qp) {
      if (!qp) {
        return;
      }

      // Update model-dep cache
      var cache = this._bucketCache;
      var cacheKey = (0, _utils.calculateCacheKey)(qp.route.fullRouteName, qp.parts, qp.values);
      cache.stash(cacheKey, prop, value);
    },

    /**
      This hook is the first of the route entry validation hooks
      called when an attempt is made to transition into a route
      or one of its children. It is called before `model` and
      `afterModel`, and is appropriate for cases when:
       1) A decision can be made to redirect elsewhere without
         needing to resolve the model first.
      2) Any async operations need to occur first before the
         model is attempted to be resolved.
       This hook is provided the current `transition` attempt
      as a parameter, which can be used to `.abort()` the transition,
      save it for a later `.retry()`, or retrieve values set
      on it from a previous hook. You can also just call
      `this.transitionTo` to another route to implicitly
      abort the `transition`.
       You can return a promise from this hook to pause the
      transition until the promise resolves (or rejects). This could
      be useful, for instance, for retrieving async code from
      the server that is required to enter a route.
       @method beforeModel
      @param {Transition} transition
      @return {any | Promise<any>} if the value returned from this hook is
        a promise, the transition will pause until the transition
        resolves. Otherwise, non-promise return values are not
        utilized in any way.
      @since 1.0.0
      @public
    */
    beforeModel: K,

    /**
      This hook is called after this route's model has resolved.
      It follows identical async/promise semantics to `beforeModel`
      but is provided the route's resolved model in addition to
      the `transition`, and is therefore suited to performing
      logic that can only take place after the model has already
      resolved.
       ```app/routes/posts.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        afterModel(posts, transition) {
          if (posts.get('length') === 1) {
            this.transitionTo('post.show', posts.get('firstObject'));
          }
        }
      });
      ```
       Refer to documentation for `beforeModel` for a description
      of transition-pausing semantics when a promise is returned
      from this hook.
       @method afterModel
      @param {Object} resolvedModel the value returned from `model`,
        or its resolved value if it was a promise
      @param {Transition} transition
      @return {any | Promise<any>} if the value returned from this hook is
        a promise, the transition will pause until the transition
        resolves. Otherwise, non-promise return values are not
        utilized in any way.
      @since 1.0.0
      @public
     */
    afterModel: K,

    /**
      A hook you can implement to optionally redirect to another route.
       If you call `this.transitionTo` from inside of this hook, this route
      will not be entered in favor of the other hook.
       `redirect` and `afterModel` behave very similarly and are
      called almost at the same time, but they have an important
      distinction in the case that, from one of these hooks, a
      redirect into a child route of this route occurs: redirects
      from `afterModel` essentially invalidate the current attempt
      to enter this route, and will result in this route's `beforeModel`,
      `model`, and `afterModel` hooks being fired again within
      the new, redirecting transition. Redirects that occur within
      the `redirect` hook, on the other hand, will _not_ cause
      these hooks to be fired again the second time around; in
      other words, by the time the `redirect` hook has been called,
      both the resolved model and attempted entry into this route
      are considered to be fully validated.
       @method redirect
      @param {Object} model the model for this route
      @param {Transition} transition the transition object associated with the current transition
      @since 1.0.0
      @public
    */
    redirect: K,

    contextDidChange: function () {
      this.currentModel = this.context;
    },
    model: function (params, transition) {
      var name = void 0,
          sawParams = void 0,
          value = void 0,
          match;
      var queryParams = (0, _emberMetal.get)(this, '_qp.map');

      for (var prop in params) {
        if (prop === 'queryParams' || queryParams && prop in queryParams) {
          continue;
        }

        match = prop.match(/^(.*)_id$/);

        if (match !== null) {
          name = match[1];
          value = params[prop];
        }
        sawParams = true;
      }

      if (!name) {
        if (sawParams) {
          return (0, _emberRuntime.copy)(params);
        } else {
          if (transition.resolveIndex < 1) {
            return;
          }
          return transition.state.handlerInfos[transition.resolveIndex - 1].context;
        }
      }

      return this.findModel(name, value);
    },
    deserialize: function (params, transition) {
      return this.model(this.paramsFor(this.routeName), transition);
    },
    findModel: function () {
      var _get;

      return (_get = (0, _emberMetal.get)(this, 'store')).find.apply(_get, arguments);
    },

    /**
      Store property provides a hook for data persistence libraries to inject themselves.
       By default, this store property provides the exact same functionality previously
      in the model hook.
       Currently, the required interface is:
       `store.find(modelName, findArguments)`
       @method store
      @param {Object} store
      @private
    */
    store: (0, _emberMetal.computed)(function () {
      var owner = (0, _emberUtils.getOwner)(this);
      var routeName = this.routeName;
      var namespace = (0, _emberMetal.get)(this, '_router.namespace');

      return {
        find: function (name, value) {
          var modelClass = owner.factoryFor('model:' + name);

          false && !!!modelClass && (0, _emberDebug.assert)('You used the dynamic segment ' + name + '_id in your route ' + routeName + ', but ' + namespace + '.' + _emberRuntime.String.classify(name) + ' did not exist and you did not override your route\'s `model` hook.', !!modelClass);

          if (!modelClass) {
            return;
          }

          modelClass = modelClass.class;

          false && !(typeof modelClass.find === 'function') && (0, _emberDebug.assert)(_emberRuntime.String.classify(name) + ' has no method `find`.', typeof modelClass.find === 'function');

          return modelClass.find(value);
        }
      };
    }),

    /**
      A hook you can implement to convert the route's model into parameters
      for the URL.
       ```app/router.js
      // ...
       Router.map(function() {
        this.route('post', { path: '/posts/:post_id' });
      });
       ```
       ```app/routes/post.js
      import $ from 'jquery';
      import Route from '@ember/routing/route';
       export default Route.extend({
        model(params) {
          // the server returns `{ id: 12 }`
          return $.getJSON('/posts/' + params.post_id);
        },
         serialize(model) {
          // this will make the URL `/posts/12`
          return { post_id: model.id };
        }
      });
      ```
       The default `serialize` method will insert the model's `id` into the
      route's dynamic segment (in this case, `:post_id`) if the segment contains '_id'.
      If the route has multiple dynamic segments or does not contain '_id', `serialize`
      will return `getProperties(model, params)`
       This method is called when `transitionTo` is called with a context
      in order to populate the URL.
       @method serialize
      @param {Object} model the routes model
      @param {Array} params an Array of parameter names for the current
        route (in the example, `['post_id']`.
      @return {Object} the serialized parameters
      @since 1.0.0
      @public
    */
    serialize: defaultSerialize,

    setupController: function (controller, context /*, transition */) {
      // eslint-disable-line no-unused-vars
      if (controller && context !== undefined) {
        (0, _emberMetal.set)(controller, 'model', context);
      }
    },
    controllerFor: function (name, _skipAssert) {
      var owner = (0, _emberUtils.getOwner)(this);
      var route = owner.lookup('route:' + name);
      var controller = void 0;

      if (route && route.controllerName) {
        name = route.controllerName;
      }

      controller = owner.lookup('controller:' + name);

      // NOTE: We're specifically checking that skipAssert is true, because according
      //   to the old API the second parameter was model. We do not want people who
      //   passed a model to skip the assertion.
      false && !(controller || _skipAssert === true) && (0, _emberDebug.assert)('The controller named \'' + name + '\' could not be found. Make sure that this route exists and has already been entered at least once. If you are accessing a controller not associated with a route, make sure the controller class is explicitly defined.', controller || _skipAssert === true);

      return controller;
    },
    generateController: function (name) {
      var owner = (0, _emberUtils.getOwner)(this);

      return (0, _generate_controller.default)(owner, name);
    },
    modelFor: function (_name) {
      var name = void 0,
          modelLookupName;
      var owner = (0, _emberUtils.getOwner)(this);
      var transition = this._router ? this._router._routerMicrolib.activeTransition : null;

      // Only change the route name when there is an active transition.
      // Otherwise, use the passed in route name.
      if (owner.routable && transition !== null) {
        name = getEngineRouteName(owner, _name);
      } else {
        name = _name;
      }

      var route = owner.lookup('route:' + name);
      // If we are mid-transition, we want to try and look up
      // resolved parent contexts on the current transitionEvent.
      if (transition !== null) {
        modelLookupName = route && route.routeName || name;

        if (transition.resolvedModels.hasOwnProperty(modelLookupName)) {
          return transition.resolvedModels[modelLookupName];
        }
      }

      return route && route.currentModel;
    },
    renderTemplate: function () /* controller, model */{
      // eslint-disable-line no-unused-vars
      this.render();
    },
    render: function (_name, options) {
      var name = void 0;
      var isDefaultRender = arguments.length === 0;
      if (!isDefaultRender) {
        if (typeof _name === 'object' && !options) {
          name = this.templateName || this.routeName;
          options = _name;
        } else {
          false && !!(0, _emberMetal.isEmpty)(_name) && (0, _emberDebug.assert)('The name in the given arguments is undefined or empty string', !(0, _emberMetal.isEmpty)(_name));

          name = _name;
        }
      }

      var renderOptions = buildRenderOptions(this, isDefaultRender, name, options);
      this.connections.push(renderOptions);
      (0, _emberMetal.once)(this._router, '_setOutlets');
    },
    disconnectOutlet: function (options) {
      var outletName = void 0,
          i;
      var parentView = void 0;
      if (options) {
        if (typeof options === 'string') {
          outletName = options;
        } else {
          outletName = options.outlet;
          parentView = options.parentView ? options.parentView.replace(/\//g, '.') : undefined;

          false && !!('outlet' in options && options.outlet === undefined) && (0, _emberDebug.assert)('You passed undefined as the outlet name.', !('outlet' in options && options.outlet === undefined));
        }
      }

      outletName = outletName || 'main';
      this._disconnectOutlet(outletName, parentView);
      var handlerInfos = this._router._routerMicrolib.currentHandlerInfos;
      for (i = 0; i < handlerInfos.length; i++) {
        // This non-local state munging is sadly necessary to maintain
        // backward compatibility with our existing semantics, which allow
        // any route to disconnectOutlet things originally rendered by any
        // other route. This should all get cut in 2.0.
        handlerInfos[i].handler._disconnectOutlet(outletName, parentView);
      }
    },
    _disconnectOutlet: function (outletName, parentView) {
      var parent = parentRoute(this),
          i,
          connection;
      if (parent && parentView === parent.routeName) {
        parentView = undefined;
      }
      for (i = 0; i < this.connections.length; i++) {
        connection = this.connections[i];

        if (connection.outlet === outletName && connection.into === parentView) {
          // This neuters the disconnected outlet such that it doesn't
          // render anything, but it leaves an entry in the outlet
          // hierarchy so that any existing other renders that target it
          // don't suddenly blow up. They will still stick themselves
          // into its outlets, which won't render anywhere. All of this
          // statefulness should get the machete in 2.0.
          this.connections[i] = {
            owner: connection.owner,
            into: connection.into,
            outlet: connection.outlet,
            name: connection.name,
            controller: undefined,
            template: undefined
          };
          (0, _emberMetal.once)(this._router, '_setOutlets');
        }
      }
    },
    willDestroy: function () {
      this.teardownViews();
    },
    teardownViews: function () {
      if (this.connections && this.connections.length > 0) {
        this.connections = [];
        (0, _emberMetal.once)(this._router, '_setOutlets');
      }
    }
  });

  Route.reopenClass({
    isRouteFactory: true
  });

  function parentRoute(route) {
    var handlerInfo = handlerInfoFor(route, route._router._routerMicrolib.state.handlerInfos, -1);
    return handlerInfo && handlerInfo.handler;
  }

  function handlerInfoFor(route, handlerInfos) {
    var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0,
        i;

    if (!handlerInfos) {
      return;
    }

    var current = void 0;
    for (i = 0; i < handlerInfos.length; i++) {
      current = handlerInfos[i].handler;
      if (current === route) {
        return handlerInfos[i + offset];
      }
    }
  }

  function buildRenderOptions(route, isDefaultRender, _name, options) {
    false && !(isDefaultRender || !(options && 'outlet' in options && options.outlet === undefined)) && (0, _emberDebug.assert)('You passed undefined as the outlet name.', isDefaultRender || !(options && 'outlet' in options && options.outlet === undefined));

    var owner = (0, _emberUtils.getOwner)(route),
        controllerName;
    var name = void 0,
        templateName = void 0,
        into = void 0,
        outlet = void 0,
        controller = void 0,
        model = void 0;
    if (options) {
      into = options.into && options.into.replace(/\//g, '.');
      outlet = options.outlet;
      controller = options.controller;
      model = options.model;
    }
    outlet = outlet || 'main';

    if (isDefaultRender) {
      name = route.routeName;
      templateName = route.templateName || name;
    } else {
      name = _name.replace(/\//g, '.');
      templateName = name;
    }

    if (!controller) {
      if (isDefaultRender) {
        controller = route.controllerName || owner.lookup('controller:' + name);
      } else {
        controller = owner.lookup('controller:' + name) || route.controllerName || route.routeName;
      }
    }

    if (typeof controller === 'string') {
      controllerName = controller;

      controller = owner.lookup('controller:' + controllerName);
      false && !(isDefaultRender || controller) && (0, _emberDebug.assert)('You passed `controller: \'' + controllerName + '\'` into the `render` method, but no such controller could be found.', isDefaultRender || controller);
    }

    if (model) {
      controller.set('model', model);
    }

    var template = owner.lookup('template:' + templateName);
    false && !(isDefaultRender || template) && (0, _emberDebug.assert)('Could not find "' + templateName + '" template, view, or component.', isDefaultRender || template);

    var parent = void 0;
    if (into && (parent = parentRoute(route)) && into === parent.routeName) {
      into = undefined;
    }

    var renderOptions = {
      owner: owner,
      into: into,
      outlet: outlet,
      name: name,
      controller: controller,
      template: template || route._topLevelViewTemplate
    };

    return renderOptions;
  }

  function getFullQueryParams(router, state) {
    if (state.fullQueryParams) {
      return state.fullQueryParams;
    }

    state.fullQueryParams = {};
    (0, _emberUtils.assign)(state.fullQueryParams, state.queryParams);

    router._deserializeQueryParams(state.handlerInfos, state.fullQueryParams);
    return state.fullQueryParams;
  }

  function getQueryParamsFor(route, state) {
    state.queryParamsFor = state.queryParamsFor || {};
    var name = route.fullRouteName,
        i,
        qp,
        qpValueWasPassedIn;

    if (state.queryParamsFor[name]) {
      return state.queryParamsFor[name];
    }

    var fullQueryParams = getFullQueryParams(route._router, state);

    var params = state.queryParamsFor[name] = {};

    // Copy over all the query params for this route/controller into params hash.
    var qpMeta = (0, _emberMetal.get)(route, '_qp');
    var qps = qpMeta.qps;
    for (i = 0; i < qps.length; ++i) {
      // Put deserialized qp on params hash.
      qp = qps[i];
      qpValueWasPassedIn = qp.prop in fullQueryParams;

      params[qp.prop] = qpValueWasPassedIn ? fullQueryParams[qp.prop] : copyDefaultValue(qp.defaultValue);
    }

    return params;
  }

  function copyDefaultValue(value) {
    if (Array.isArray(value)) {
      return (0, _emberRuntime.A)(value.slice());
    }
    return value;
  }

  /*
    Merges all query parameters from a controller with those from
    a route, returning a new object and avoiding any mutations to
    the existing objects.
  */
  function mergeEachQueryParams(controllerQP, routeQP) {
    var qps = {},
        newControllerParameterConfiguration,
        newRouteParameterConfiguration;
    var keysAlreadyMergedOrSkippable = {
      defaultValue: true,
      type: true,
      scope: true,
      as: true
    };

    // first loop over all controller qps, merging them with any matching route qps
    // into a new empty object to avoid mutating.
    for (var cqpName in controllerQP) {
      if (!controllerQP.hasOwnProperty(cqpName)) {
        continue;
      }

      newControllerParameterConfiguration = {};

      (0, _emberUtils.assign)(newControllerParameterConfiguration, controllerQP[cqpName], routeQP[cqpName]);

      qps[cqpName] = newControllerParameterConfiguration;

      // allows us to skip this QP when we check route QPs.
      keysAlreadyMergedOrSkippable[cqpName] = true;
    }

    // loop over all route qps, skipping those that were merged in the first pass
    // because they also appear in controller qps
    for (var rqpName in routeQP) {
      if (!routeQP.hasOwnProperty(rqpName) || keysAlreadyMergedOrSkippable[rqpName]) {
        continue;
      }

      newRouteParameterConfiguration = {};

      (0, _emberUtils.assign)(newRouteParameterConfiguration, routeQP[rqpName], controllerQP[rqpName]);
      qps[rqpName] = newRouteParameterConfiguration;
    }

    return qps;
  }

  function addQueryParamsObservers(controller, propNames) {
    propNames.forEach(function (prop) {
      controller.addObserver(prop + '.[]', controller, controller._qpChanged);
    });
  }

  function getEngineRouteName(engine, routeName) {
    var prefix;

    if (engine.routable) {
      prefix = engine.mountPoint;


      if (routeName === 'application') {
        return prefix;
      } else {
        return prefix + '.' + routeName;
      }
    }

    return routeName;
  }

  exports.default = Route;
});