enifed('ember/index', ['exports', 'require', 'ember-environment', 'node-module', 'ember-utils', 'container', 'ember-metal', 'ember/features', 'ember-debug', 'backburner', 'ember-console', 'ember-runtime', 'ember-glimmer', 'ember/version', 'ember-views', 'ember-routing', 'ember-application', 'ember-extension-support'], function (exports, _require2, _emberEnvironment, _nodeModule, _emberUtils, _container, _emberMetal, _features, _emberDebug, _backburner, _emberConsole, _emberRuntime, _emberGlimmer, _version, _emberViews, _emberRouting, _emberApplication, _emberExtensionSupport) {
  'use strict';

  // ****ember-environment****

  var Ember = typeof _emberEnvironment.context.imports.Ember === 'object' && _emberEnvironment.context.imports.Ember || {},
      testing;

  Ember.isNamespace = true;
  Ember.toString = function () {
    return 'Ember';
  };

  Object.defineProperty(Ember, 'ENV', {
    get: _emberEnvironment.getENV,
    enumerable: false
  });

  Object.defineProperty(Ember, 'lookup', {
    get: _emberEnvironment.getLookup,
    set: _emberEnvironment.setLookup,
    enumerable: false
  });

  Object.defineProperty(Ember, 'EXTEND_PROTOTYPES', {
    enumerable: false,
    get: function () {
      false && !false && (0, _emberDebug.deprecate)('Accessing Ember.EXTEND_PROTOTYPES is deprecated, please migrate to Ember.ENV.EXTEND_PROTOTYPES', false, {
        id: 'ember-env.old-extend-prototypes',
        until: '4.0.0'
      });

      return _emberEnvironment.ENV.EXTEND_PROTOTYPES;
    }
  });

  // ****ember-utils****
  Ember.getOwner = _emberUtils.getOwner;
  Ember.setOwner = _emberUtils.setOwner;
  Ember.generateGuid = _emberUtils.generateGuid;
  Ember.GUID_KEY = _emberUtils.GUID_KEY;
  Ember.guidFor = _emberUtils.guidFor;
  Ember.inspect = _emberUtils.inspect;
  Ember.makeArray = _emberUtils.makeArray;
  Ember.canInvoke = _emberUtils.canInvoke;
  Ember.tryInvoke = _emberUtils.tryInvoke;
  Ember.wrap = _emberUtils.wrap;
  Ember.uuid = _emberUtils.uuid;
  Ember.assign = _emberUtils.assign;
  Ember.NAME_KEY = _emberUtils.NAME_KEY;

  // ****container****
  Ember.Container = _container.Container;
  Ember.Registry = _container.Registry;

  // ****ember-debug****
  Ember.assert = _emberDebug.assert;
  Ember.warn = _emberDebug.warn;
  Ember.debug = _emberDebug.debug;
  Ember.deprecate = _emberDebug.deprecate;
  Ember.deprecateFunc = _emberDebug.deprecateFunc;
  Ember.runInDebug = _emberDebug.runInDebug;
  Ember.Error = _emberDebug.Error;

  /**
    @public
    @class Ember.Debug
  */
  Ember.Debug = {
    registerDeprecationHandler: _emberDebug.registerDeprecationHandler,
    registerWarnHandler: _emberDebug.registerWarnHandler
  };

  // ****ember-metal****

  // Using _globalsComputed here so that mutating the function is only available
  // in globals builds
  var computed = _emberMetal._globalsComputed;
  Ember.computed = computed;
  computed.alias = _emberMetal.alias;
  Ember.ComputedProperty = _emberMetal.ComputedProperty;
  Ember.cacheFor = _emberMetal.getCachedValueFor;
  Ember.merge = _emberMetal.merge;
  Ember.instrument = _emberMetal.instrument;
  Ember.subscribe = _emberMetal.instrumentationSubscribe;
  Ember.Instrumentation = {
    instrument: _emberMetal.instrument,
    subscribe: _emberMetal.instrumentationSubscribe,
    unsubscribe: _emberMetal.instrumentationUnsubscribe,
    reset: _emberMetal.instrumentationReset
  };
  Ember.meta = _emberMetal.meta;
  Ember.get = _emberMetal.get;
  Ember.getWithDefault = _emberMetal.getWithDefault;
  Ember._getPath = _emberMetal._getPath;
  Ember.set = _emberMetal.set;
  Ember.trySet = _emberMetal.trySet;
  Ember.FEATURES = _features.FEATURES;
  Ember.FEATURES.isEnabled = _emberDebug.isFeatureEnabled;
  Ember._Cache = _emberMetal.Cache;
  Ember.on = _emberMetal.on;
  Ember.addListener = _emberMetal.addListener;
  Ember.removeListener = _emberMetal.removeListener;
  Ember.sendEvent = _emberMetal.sendEvent;
  Ember.hasListeners = _emberMetal.hasListeners;
  Ember.isNone = _emberMetal.isNone;
  Ember.isEmpty = _emberMetal.isEmpty;
  Ember.isBlank = _emberMetal.isBlank;
  Ember.isPresent = _emberMetal.isPresent;
  // Using _globalsRun here so that mutating the function (adding
  // `next`, `later`, etc to it) is only available in globals builds
  Ember.run = _emberMetal._globalsRun;
  Ember.run.backburner = _emberMetal.backburner;
  Ember.run.begin = _emberMetal.begin;
  Ember.run.bind = _emberMetal.bind;
  Ember.run.cancel = _emberMetal.cancel;
  Ember.run.debounce = _emberMetal.debounce;
  Ember.run.end = _emberMetal.end;
  Ember.run.hasScheduledTimers = _emberMetal.hasScheduledTimers;
  Ember.run.join = _emberMetal.join;
  Ember.run.later = _emberMetal.later;
  Ember.run.next = _emberMetal.next;
  Ember.run.once = _emberMetal.once;
  Ember.run.schedule = _emberMetal.schedule;
  Ember.run.scheduleOnce = _emberMetal.scheduleOnce;
  Ember.run.throttle = _emberMetal.throttle;
  Ember.run.cancelTimers = _emberMetal.cancelTimers;
  Object.defineProperty(Ember.run, 'currentRunLoop', {
    get: _emberMetal.getCurrentRunLoop,
    enumerable: false
  });
  Ember.propertyWillChange = _emberMetal.propertyWillChange;
  Ember.propertyDidChange = _emberMetal.propertyDidChange;
  Ember.notifyPropertyChange = _emberMetal.notifyPropertyChange;
  Ember.overrideChains = _emberMetal.overrideChains;
  Ember.beginPropertyChanges = _emberMetal.beginPropertyChanges;
  Ember.endPropertyChanges = _emberMetal.endPropertyChanges;
  Ember.changeProperties = _emberMetal.changeProperties;
  Ember.platform = {
    defineProperty: true,
    hasPropertyAccessors: true
  };
  Ember.defineProperty = _emberMetal.defineProperty;
  Ember.watchKey = _emberMetal.watchKey;
  Ember.unwatchKey = _emberMetal.unwatchKey;
  Ember.removeChainWatcher = _emberMetal.removeChainWatcher;
  Ember._ChainNode = _emberMetal.ChainNode;
  Ember.finishChains = _emberMetal.finishChains;
  Ember.watchPath = _emberMetal.watchPath;
  Ember.unwatchPath = _emberMetal.unwatchPath;
  Ember.watch = _emberMetal.watch;
  Ember.isWatching = _emberMetal.isWatching;
  Ember.unwatch = _emberMetal.unwatch;
  Ember.destroy = _emberMetal.deleteMeta;
  Ember.libraries = _emberMetal.libraries;
  Ember.OrderedSet = _emberMetal.OrderedSet;
  Ember.Map = _emberMetal.Map;
  Ember.MapWithDefault = _emberMetal.MapWithDefault;
  Ember.getProperties = _emberMetal.getProperties;
  Ember.setProperties = _emberMetal.setProperties;
  Ember.expandProperties = _emberMetal.expandProperties;
  Ember.addObserver = _emberMetal.addObserver;
  Ember.removeObserver = _emberMetal.removeObserver;
  Ember.aliasMethod = _emberMetal.aliasMethod;
  Ember.observer = _emberMetal.observer;
  Ember.mixin = _emberMetal.mixin;
  Ember.Mixin = _emberMetal.Mixin;

  /**
    A function may be assigned to `Ember.onerror` to be called when Ember
    internals encounter an error. This is useful for specialized error handling
    and reporting code.
  
    ```javascript
    import $ from 'jquery';
  
    Ember.onerror = function(error) {
      $.ajax('/report-error', 'POST', {
        stack: error.stack,
        otherInformation: 'whatever app state you want to provide'
      });
    };
    ```
  
    Internally, `Ember.onerror` is used as Backburner's error handler.
  
    @event onerror
    @for Ember
    @param {Exception} error the error object
    @public
  */
  Object.defineProperty(Ember, 'onerror', {
    get: _emberMetal.getOnerror,
    set: _emberMetal.setOnerror,
    enumerable: false
  });

  Object.defineProperty(Ember, 'testing', {
    get: _emberDebug.isTesting,
    set: _emberDebug.setTesting,
    enumerable: false
  });

  Ember._Backburner = _backburner.default;

  // ****ember-console****
  Ember.Logger = _emberConsole.default;

  // ****ember-runtime****
  Ember.A = _emberRuntime.A;
  Ember.String = _emberRuntime.String;
  Ember.Object = _emberRuntime.Object;
  Ember._RegistryProxyMixin = _emberRuntime.RegistryProxyMixin;
  Ember._ContainerProxyMixin = _emberRuntime.ContainerProxyMixin;
  Ember.compare = _emberRuntime.compare;
  Ember.copy = _emberRuntime.copy;
  Ember.isEqual = _emberRuntime.isEqual;
  Ember.inject = _emberRuntime.inject;
  Ember.Array = _emberRuntime.Array;
  Ember.Comparable = _emberRuntime.Comparable;
  Ember.Enumerable = _emberRuntime.Enumerable;
  Ember.ArrayProxy = _emberRuntime.ArrayProxy;
  Ember.ObjectProxy = _emberRuntime.ObjectProxy;
  Ember.ActionHandler = _emberRuntime.ActionHandler;
  Ember.CoreObject = _emberRuntime.CoreObject;
  Ember.NativeArray = _emberRuntime.NativeArray;
  Ember.Copyable = _emberRuntime.Copyable;
  Ember.MutableEnumerable = _emberRuntime.MutableEnumerable;
  Ember.MutableArray = _emberRuntime.MutableArray;
  Ember.TargetActionSupport = _emberRuntime.TargetActionSupport;
  Ember.Evented = _emberRuntime.Evented;
  Ember.PromiseProxyMixin = _emberRuntime.PromiseProxyMixin;
  Ember.Observable = _emberRuntime.Observable;
  Ember.typeOf = _emberRuntime.typeOf;
  Ember.isArray = _emberRuntime.isArray;
  Ember.Object = _emberRuntime.Object;
  Ember.onLoad = _emberRuntime.onLoad;
  Ember.runLoadHooks = _emberRuntime.runLoadHooks;
  Ember.Controller = _emberRuntime.Controller;
  Ember.ControllerMixin = _emberRuntime.ControllerMixin;
  Ember.Service = _emberRuntime.Service;
  Ember._ProxyMixin = _emberRuntime._ProxyMixin;
  Ember.RSVP = _emberRuntime.RSVP;
  Ember.Namespace = _emberRuntime.Namespace;

  computed.empty = _emberRuntime.empty;
  computed.notEmpty = _emberRuntime.notEmpty;
  computed.none = _emberRuntime.none;
  computed.not = _emberRuntime.not;
  computed.bool = _emberRuntime.bool;
  computed.match = _emberRuntime.match;
  computed.equal = _emberRuntime.equal;
  computed.gt = _emberRuntime.gt;
  computed.gte = _emberRuntime.gte;
  computed.lt = _emberRuntime.lt;
  computed.lte = _emberRuntime.lte;
  computed.oneWay = _emberRuntime.oneWay;
  computed.reads = _emberRuntime.oneWay;
  computed.readOnly = _emberRuntime.readOnly;
  computed.deprecatingAlias = _emberRuntime.deprecatingAlias;
  computed.and = _emberRuntime.and;
  computed.or = _emberRuntime.or;

  computed.sum = _emberRuntime.sum;
  computed.min = _emberRuntime.min;
  computed.max = _emberRuntime.max;
  computed.map = _emberRuntime.map;
  computed.sort = _emberRuntime.sort;
  computed.setDiff = _emberRuntime.setDiff;
  computed.mapBy = _emberRuntime.mapBy;
  computed.filter = _emberRuntime.filter;
  computed.filterBy = _emberRuntime.filterBy;
  computed.uniq = _emberRuntime.uniq;

  computed.uniqBy = _emberRuntime.uniqBy;
  computed.union = _emberRuntime.union;
  computed.intersect = _emberRuntime.intersect;
  computed.collect = _emberRuntime.collect;

  /**
    Defines the hash of localized strings for the current language. Used by
    the `String.loc` helper. To localize, add string values to this
    hash.
  
    @property STRINGS
    @for Ember
    @type Object
    @private
  */
  Object.defineProperty(Ember, 'STRINGS', {
    configurable: false,
    get: _emberRuntime.getStrings,
    set: _emberRuntime.setStrings
  });

  /**
    Whether searching on the global for new Namespace instances is enabled.
  
    This is only exported here as to not break any addons.  Given the new
    visit API, you will have issues if you treat this as a indicator of
    booted.
  
    Internally this is only exposing a flag in Namespace.
  
    @property BOOTED
    @for Ember
    @type Boolean
    @private
  */
  Object.defineProperty(Ember, 'BOOTED', {
    configurable: false,
    enumerable: false,
    get: _emberMetal.isNamespaceSearchDisabled,
    set: _emberMetal.setNamespaceSearchDisabled
  });

  // ****ember-glimmer****
  Ember.Component = _emberGlimmer.Component;
  _emberGlimmer.Helper.helper = _emberGlimmer.helper;
  Ember.Helper = _emberGlimmer.Helper;
  Ember.Checkbox = _emberGlimmer.Checkbox;
  Ember.TextField = _emberGlimmer.TextField;
  Ember.TextArea = _emberGlimmer.TextArea;
  Ember.LinkComponent = _emberGlimmer.LinkComponent;
  Ember._setComponentManager = _emberGlimmer.componentManager;
  Ember.Handlebars = {
    template: _emberGlimmer.template,
    Utils: {
      escapeExpression: _emberGlimmer.escapeExpression
    }
  };
  Ember.HTMLBars = {
    template: _emberGlimmer.template
  };

  if (_emberEnvironment.ENV.EXTEND_PROTOTYPES.String) {
    String.prototype.htmlSafe = function () {
      return (0, _emberGlimmer.htmlSafe)(this);
    };
  }
  _emberRuntime.String.htmlSafe = _emberGlimmer.htmlSafe;
  _emberRuntime.String.isHTMLSafe = _emberGlimmer.isHTMLSafe;

  /**
    Global hash of shared templates. This will automatically be populated
    by the build tools so that you can store your Handlebars templates in
    separate files that get loaded into JavaScript at buildtime.
  
    @property TEMPLATES
    @for Ember
    @type Object
    @private
  */
  Object.defineProperty(Ember, 'TEMPLATES', {
    get: _emberGlimmer.getTemplates,
    set: _emberGlimmer.setTemplates,
    configurable: false,
    enumerable: false
  });

  /**
    The semantic version
  
    @property VERSION
    @type String
    @public
  */
  Ember.VERSION = _version.default;

  // ****ember-views****
  Ember.$ = _emberViews.jQuery;
  Ember.ViewUtils = {
    isSimpleClick: _emberViews.isSimpleClick,
    getViewElement: _emberViews.getViewElement,
    getViewBounds: _emberViews.getViewBounds,
    getViewClientRects: _emberViews.getViewClientRects,
    getViewBoundingClientRect: _emberViews.getViewBoundingClientRect,
    getRootViews: _emberViews.getRootViews,
    getChildViews: _emberViews.getChildViews,
    isSerializationFirstNode: _emberGlimmer.isSerializationFirstNode
  };
  Ember.TextSupport = _emberViews.TextSupport;
  Ember.ComponentLookup = _emberViews.ComponentLookup;
  Ember.EventDispatcher = _emberViews.EventDispatcher;

  // ****ember-routing****
  Ember.Location = _emberRouting.Location;
  Ember.AutoLocation = _emberRouting.AutoLocation;
  Ember.HashLocation = _emberRouting.HashLocation;
  Ember.HistoryLocation = _emberRouting.HistoryLocation;
  Ember.NoneLocation = _emberRouting.NoneLocation;
  Ember.controllerFor = _emberRouting.controllerFor;
  Ember.generateControllerFactory = _emberRouting.generateControllerFactory;
  Ember.generateController = _emberRouting.generateController;
  Ember.RouterDSL = _emberRouting.RouterDSL;
  Ember.Router = _emberRouting.Router;
  Ember.Route = _emberRouting.Route;

  // ****ember-application****
  Ember.Application = _emberApplication.Application;
  Ember.ApplicationInstance = _emberApplication.ApplicationInstance;
  Ember.Engine = _emberApplication.Engine;
  Ember.EngineInstance = _emberApplication.EngineInstance;
  Ember.DefaultResolver = Ember.Resolver = _emberApplication.Resolver;

  (0, _emberRuntime.runLoadHooks)('Ember.Application', _emberApplication.Application);

  Ember.DataAdapter = _emberExtensionSupport.DataAdapter;
  Ember.ContainerDebugAdapter = _emberExtensionSupport.ContainerDebugAdapter;

  if ((0, _require2.has)('ember-template-compiler')) {
    (0, _require2.default)('ember-template-compiler');
  }

  // do this to ensure that Ember.Test is defined properly on the global
  // if it is present.
  if ((0, _require2.has)('ember-testing')) {
    testing = (0, _require2.default)('ember-testing');


    Ember.Test = testing.Test;
    Ember.Test.Adapter = testing.Adapter;
    Ember.Test.QUnitAdapter = testing.QUnitAdapter;
    Ember.setupForTesting = testing.setupForTesting;
  }

  (0, _emberRuntime.runLoadHooks)('Ember');

  exports.default = Ember;

  if (_nodeModule.IS_NODE) {
    _nodeModule.module.exports = Ember;
  } else {
    _emberEnvironment.context.exports.Ember = _emberEnvironment.context.exports.Em = Ember;
  }

  /**
   @module jquery
   @public
   */

  /**
   @class jquery
   @public
   @static
   */

  /**
    Alias for jQuery
  
    @for jquery
    @method $
    @static
    @public
  */
});