define("ember-bootstrap/templates/components/bs-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "REqX1A6v",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[13,1,[[26,\"hash\",null,[[\"button\"],[[26,\"component\",[[22,[\"buttonComponent\"]]],[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[22,[\"type\"]],[22,[\"value\"]],[26,\"action\",[[21,0,[]],\"buttonPressed\"],null]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button-group.hbs"
    }
  });

  _exports.default = _default;
});