enifed('ember-runtime/lib/system/object', ['exports', 'container', 'ember-utils', 'ember-metal', 'ember-runtime/lib/system/core_object', 'ember-runtime/lib/mixins/observable', 'ember-debug'], function (exports, _container, _emberUtils, _emberMetal, _core_object, _observable) {
  'use strict';

  exports.FrameworkObject = undefined;

  var _CoreObject$extend;

  var OVERRIDE_OWNER = (0, _emberUtils.symbol)('OVERRIDE_OWNER');

  /**
    `EmberObject` is the main base class for all Ember objects. It is a subclass
    of `CoreObject` with the `Observable` mixin applied. For details,
    see the documentation for each of these.
  
    @class EmberObject
    @extends CoreObject
    @uses Observable
    @public
  */
  var EmberObject = _core_object.default.extend(_observable.default, (_CoreObject$extend = {
    _debugContainerKey: (0, _emberMetal.descriptor)({
      enumerable: false,
      get: function () {
        var factory = _container.FACTORY_FOR.get(this);

        return factory !== undefined && factory.fullName;
      }
    })

  }, _CoreObject$extend[_emberUtils.OWNER] = (0, _emberMetal.descriptor)({
    enumerable: false,
    get: function () {
      if (this[OVERRIDE_OWNER]) {
        return this[OVERRIDE_OWNER];
      }

      var factory = _container.FACTORY_FOR.get(this);

      return factory !== undefined && factory.owner;
    },
    set: function (value) {
      this[OVERRIDE_OWNER] = value;
    }
  }), _CoreObject$extend));

  (0, _emberUtils.setName)(EmberObject, 'Ember.Object');

  exports.FrameworkObject = EmberObject;


  exports.default = EmberObject;
});