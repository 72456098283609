define("ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bmFEdQqY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"form-check\"],[8],[0,\"\\n  \"],[13,1],[0,\"\\n  \"],[1,[26,\"component\",[[22,[\"labelComponent\"]]],null],false],[0,\"\\n  \"],[1,[26,\"component\",[[22,[\"errorsComponent\"]]],null],false],[0,\"\\n  \"],[1,[26,\"component\",[[22,[\"helpTextComponent\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox.hbs"
    }
  });

  _exports.default = _default;
});