define("ocularium-frontend-core/helpers/format-date-mysql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDateMysql = formatDateMysql;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function twoDigits(d) {
    if (0 <= d && d < 10) {
      return "0" + d.toString();
    }

    if (-10 < d && d < 0) {
      return "-0" + (-1 * d).toString();
    }

    return d.toString();
  }

  function toMySQLFormat(value) {
    if (!value) {
      return "-";
    }

    return value.getUTCFullYear() + "-" + twoDigits(1 + value.getUTCMonth()) + "-" + twoDigits(value.getUTCDate()) + " " + twoDigits(value.getUTCHours()) + ":" + twoDigits(value.getUTCMinutes()) + ":" + twoDigits(value.getUTCSeconds()) + "." + value.getMilliseconds();
  }

  function formatDateMysql(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        value = _ref2[0];

    return toMySQLFormat(value);
  }

  var _default = Ember.Helper.helper(formatDateMysql);

  _exports.default = _default;
});