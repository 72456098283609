define("ember-bootstrap/templates/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4XpYDHUg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[13,1,[[26,\"hash\",null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\",\"submit\"],[[26,\"component\",[[22,[\"elementComponent\"]]],[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"disabled\",\"readonly\",\"onChange\",\"_onChange\"],[[22,[\"model\"]],[22,[\"formLayout\"]],[22,[\"horizontalLabelGridClass\"]],[22,[\"showAllValidations\"]],[21,0,[\"disabled\"]],[21,0,[\"readonly\"]],[26,\"action\",[[21,0,[]],\"change\"],null],[26,\"action\",[[21,0,[]],\"resetSubmissionState\"],null]]]],[26,\"component\",[[22,[\"groupComponent\"]]],[[\"formLayout\"],[[22,[\"formLayout\"]]]]],[22,[\"isSubmitting\"]],[22,[\"isSubmitted\"]],[22,[\"isRejected\"]],[26,\"action\",[[21,0,[]],\"resetSubmissionState\"],null],[26,\"action\",[[21,0,[]],\"submit\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form.hbs"
    }
  });

  _exports.default = _default;
});