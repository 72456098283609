define("ember-cli-swiper/templates/components/swiper-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SgXJB+wQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"swiper-wrapper\"],[8],[0,\"\\n  \"],[13,1,[[26,\"hash\",null,[[\"slide\"],[[26,\"component\",[\"swiper-slide\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"hasPagination\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"swiper-pagination\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"hasNavigation\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[11,\"class\",[27,[[20,\"nextElClass\"]]]],[8],[9],[0,\"\\n  \"],[6,\"div\"],[11,\"class\",[27,[[20,\"prevElClass\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-swiper/templates/components/swiper-container.hbs"
    }
  });

  _exports.default = _default;
});