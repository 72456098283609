define("ocularium-frontend-core/services/weather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getWeather: function getWeather(city, country) {
      var key = "f30283067ebd36f53ed10a56071d66f1";
      return new Promise(function (resolve, reject) {
        return fetch('https://api.openweathermap.org/data/2.5/weather?q=' + city + ',' + country + '&units=metric&appid=' + key).then(function (res) {
          resolve(res.json());
        }).catch(function (err) {
          console.warn('-- error fetching weather: ', err, ' --');
          reject(err);
        });
      });
    }
  });

  _exports.default = _default;
});