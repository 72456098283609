define("ocularium-frontend-core/services/asset-bundle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    translations: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('translations', []);
    },
    fetchAssetTranslations: function fetchAssetTranslations(activeApplicationID) {
      var _this = this;

      this.get('store').findRecord('modules/assetBundle/assetbundle-settings', activeApplicationID).then(function (result) {
        _this.set('translations', result.get('files'));
      }).catch(function () {
        console.error('-- Something went wrong fetching the asset bundle data --');
      });
    },
    getTranslations: function getTranslations() {
      return this.get('translations');
    }
  });

  _exports.default = _default;
});