define("ember-bootstrap/templates/components/bs-form/element/control/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D09nsijc",
    "block": "{\"symbols\":[\"option\",\"index\",\"id\",\"&default\"],\"statements\":[[4,\"each\",[[22,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[11,\"class\",[27,[\"form-check\",[26,\"if\",[[22,[\"inline\"]],\" form-check-inline\"],null]]]],[8],[0,\"\\n\"],[4,\"with\",[[26,\"concat\",[[21,0,[\"elementId\"]],\"-\",[21,2,[]]],null]],null,{\"statements\":[[0,\"      \"],[6,\"input\"],[10,\"class\",\"form-check-input\"],[11,\"id\",[21,3,[]]],[11,\"checked\",[26,\"bs-eq\",[[21,1,[]],[22,[\"value\"]]],null]],[11,\"onclick\",[26,\"action\",[[21,0,[]],[22,[\"onChange\"]],[21,1,[]]],null]],[11,\"name\",[20,\"name\"]],[11,\"required\",[20,\"required\"]],[11,\"disabled\",[20,\"disabled\"]],[11,\"autofocus\",[20,\"autofocus\"]],[11,\"tabindex\",[20,\"tabindex\"]],[11,\"form\",[20,\"form\"]],[11,\"title\",[20,\"title\"]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n      \"],[6,\"label\"],[11,\"for\",[21,3,[]]],[10,\"class\",\"form-check-label\"],[8],[0,\"\\n\"],[4,\"if\",[[23,4]],null,{\"statements\":[[0,\"          \"],[13,4,[[21,1,[]],[21,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,[\"optionLabelPath\"]]],null,{\"statements\":[[0,\"            \"],[1,[26,\"get\",[[21,1,[]],[22,[\"optionLabelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[21,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/radio.hbs"
    }
  });

  _exports.default = _default;
});