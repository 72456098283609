define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oHYretq1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,[\"fluid\"]]],null,{\"statements\":[[0,\"  \"],[13,1,[[26,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[26,\"component\",[[22,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[26,\"action\",[[21,0,[]],\"toggleNavbar\"],null],[22,[\"_collapsed\"]]]]],[26,\"component\",[[22,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[22,[\"_collapsed\"]],[22,[\"onCollapsed\"]],[22,[\"onExpanded\"]]]]],[26,\"component\",[[22,[\"navComponent\"]]],[[\"linkToComponent\"],[[26,\"component\",[[22,[\"linkToComponent\"]]],[[\"onCollapse\"],[[26,\"action\",[[21,0,[]],\"collapse\"],null]]]]]]],[26,\"action\",[[21,0,[]],\"collapse\"],null],[26,\"action\",[[21,0,[]],\"expand\"],null],[26,\"action\",[[21,0,[]],\"toggleNavbar\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[13,1,[[26,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[26,\"component\",[[22,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[26,\"action\",[[21,0,[]],\"toggleNavbar\"],null],[22,[\"_collapsed\"]]]]],[26,\"component\",[[22,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[22,[\"_collapsed\"]],[22,[\"onCollapsed\"]],[22,[\"onExpanded\"]]]]],[26,\"component\",[[22,[\"navComponent\"]]],[[\"linkToComponent\"],[[26,\"component\",[[22,[\"linkToComponent\"]]],[[\"onCollapse\"],[[26,\"action\",[[21,0,[]],\"collapse\"],null]]]]]]],[26,\"action\",[[21,0,[]],\"collapse\"],null],[26,\"action\",[[21,0,[]],\"expand\"],null],[26,\"action\",[[21,0,[]],\"toggleNavbar\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs"
    }
  });

  _exports.default = _default;
});