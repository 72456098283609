enifed('ember-runtime/lib/controllers/controller', ['exports', 'ember-debug', 'ember-runtime/lib/system/object', 'ember-runtime/lib/mixins/controller', 'ember-runtime/lib/inject'], function (exports, _emberDebug, _object, _controller, _inject) {
  'use strict';

  /**
  @module @ember/controller
  */

  /**
    @class Controller
    @extends EmberObject
    @uses Ember.ControllerMixin
    @public
  */

  var Controller = _object.default.extend(_controller.default);

  /**
    Creates a property that lazily looks up another controller in the container.
    Can only be used when defining another controller.
  
    Example:
  
    ```app/controllers/post.js
    import Controller, {
      inject as controller
    } from '@ember/controller';
  
    export default Controller.extend({
      posts: controller()
    });
    ```
  
    This example will create a `posts` property on the `post` controller that
    looks up the `posts` controller in the container, making it easy to
    reference other controllers.
  
    @method inject
    @static
    @for @ember/controller
    @since 1.10.0
    @param {String} name (optional) name of the controller to inject, defaults
           to the property's name
    @return {Ember.InjectedProperty} injection descriptor instance
    @public
  */
  (0, _inject.createInjectionHelper)('controller', function (factory) {
    false && !_controller.default.detect(factory.PrototypeMixin) && (0, _emberDebug.assert)('Defining an injected controller property on a ' + 'non-controller is not allowed.', _controller.default.detect(factory.PrototypeMixin));
  });

  exports.default = Controller;
});