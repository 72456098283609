define("ember-bootstrap/templates/components/bs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S7031ANG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[26,\"unbound\",[[22,[\"_parentFinder\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[22,[\"inDom\"]]],null,{\"statements\":[[4,\"component\",[[22,[\"elementComponent\"]]],[[\"id\",\"placement\",\"fade\",\"showHelp\",\"class\",\"renderInPlace\",\"popperTarget\",\"autoPlacement\",\"viewportElement\",\"viewportPadding\"],[[22,[\"overlayId\"]],[22,[\"placement\"]],[22,[\"fade\"]],[22,[\"showHelp\"]],[22,[\"class\"]],[22,[\"_renderInPlace\"]],[22,[\"triggerTargetElement\"]],[22,[\"autoPlacement\"]],[22,[\"viewportElement\"]],[22,[\"viewportPadding\"]]]],{\"statements\":[[4,\"if\",[[23,1]],null,{\"statements\":[[0,\"      \"],[13,1,[[26,\"hash\",null,[[\"close\"],[[26,\"action\",[[21,0,[]],\"close\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[20,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip.hbs"
    }
  });

  _exports.default = _default;
});