define("ocularium-frontend-core/index", ["exports", "ocularium-frontend-core/lib/data/requests", "ocularium-frontend-core/lib/prefixes/ModelPrefix", "ocularium-frontend-core/lib/prefixes/ModuleRoutePrefix", "ocularium-frontend-core/lib/style", "ocularium-frontend-core/lib/data/labels/defaultModuleLabels", "ocularium-frontend-core/lib/constants/ocularErrors", "gsap/all"], function (_exports, _requests, _ModelPrefix, _ModuleRoutePrefix, _style, _defaultModuleLabels, _ocularErrors, _all) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "createRequest", {
    enumerable: true,
    get: function get() {
      return _requests.createRequest;
    }
  });
  Object.defineProperty(_exports, "getModelPrefix", {
    enumerable: true,
    get: function get() {
      return _ModelPrefix.getModelPrefix;
    }
  });
  Object.defineProperty(_exports, "getModuleRoutePrefix", {
    enumerable: true,
    get: function get() {
      return _ModuleRoutePrefix.getModuleRoutePrefix;
    }
  });
  Object.defineProperty(_exports, "addCustomClassName", {
    enumerable: true,
    get: function get() {
      return _style.addCustomClassName;
    }
  });
  Object.defineProperty(_exports, "defaultModuleLabels", {
    enumerable: true,
    get: function get() {
      return _defaultModuleLabels.labels;
    }
  });
  Object.defineProperty(_exports, "OcularErrors", {
    enumerable: true,
    get: function get() {
      return _ocularErrors.default;
    }
  });
  Object.defineProperty(_exports, "TweenMax", {
    enumerable: true,
    get: function get() {
      return _all.TweenMax;
    }
  });
  Object.defineProperty(_exports, "TweenLite", {
    enumerable: true,
    get: function get() {
      return _all.TweenLite;
    }
  });
  Object.defineProperty(_exports, "Draggable", {
    enumerable: true,
    get: function get() {
      return _all.Draggable;
    }
  });
  Object.defineProperty(_exports, "TimelineMax", {
    enumerable: true,
    get: function get() {
      return _all.TimelineMax;
    }
  });
  Object.defineProperty(_exports, "TimelineLite", {
    enumerable: true,
    get: function get() {
      return _all.TimelineLite;
    }
  });
  Object.defineProperty(_exports, "BezierPlugin", {
    enumerable: true,
    get: function get() {
      return _all.BezierPlugin;
    }
  });
});