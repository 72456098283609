define("ember-bootstrap/templates/components/bs-accordion/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R7Z5LMlG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[[22,[\"collapseComponent\"]]],[[\"ariaRole\",\"collapsed\"],[\"tabpanel\",[22,[\"collapsed\"]]]],{\"statements\":[[0,\"  \"],[6,\"div\"],[11,\"class\",[27,[\"card-body \",[20,\"class\"]]]],[8],[0,\"\\n    \"],[13,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/body.hbs"
    }
  });

  _exports.default = _default;
});