define("ocularium-frontend-core/services/user-idle", ["exports", "ember-user-activity/services/user-idle"], function (_exports, _userIdle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userIdle.default.extend({
    router: Ember.inject.service(),
    boot: Ember.inject.service(),
    breadcrumbsService: Ember.inject.service('navigation/breadcrumbs'),
    IDLE_TIMEOUT: 100,
    ENABLED: false,
    ACTIVE: false,
    WARNING_VISIBLE: false,
    WARNING_COUNT: 10,
    WARNING: true,
    ACTIVE_SCREENSAVER_ID: null,
    onIdle: function onIdle(isIdle) {
      if (isIdle && this.get('ENABLED')) {
        if (this.get('WARNING')) {
          if (this.checkUserIdleWarning()) {
            this.set('WARNING_VISIBLE', true);
            return;
          } else {
            this.navigateToScreensaver();
          }
        }

        this.navigateToScreensaver();
      }
    },
    checkUserIdleWarning: function checkUserIdleWarning() {
      if (this.get('ACTIVE')) {
        return false;
      } else if (this.get('boot.isBooting')) {
        return false;
      }

      return true;
    },
    navigateToScreensaver: function navigateToScreensaver() {
      var _this = this;

      Ember.run.later(function () {
        _this.set('boot.isBooting', false);

        _this.get('breadcrumbsService').clearBreadcrumbs();

        _this.get('router').transitionTo('modules.screensaver', _this.get('ACTIVE_SCREENSAVER_ID'));
      }, 0);
    }
  });

  _exports.default = _default;
});