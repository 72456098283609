define("ember-bootstrap/templates/components/bs-accordion/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wul2xU4Y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h5\"],[10,\"class\",\"mb-0\"],[8],[0,\"\\n  \"],[6,\"button\"],[11,\"class\",[27,[\"btn btn-link \",[26,\"if\",[[22,[\"disabled\"]],\"disabled\"],null]]]],[11,\"disabled\",[20,\"disabled\"]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[13,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/title.hbs"
    }
  });

  _exports.default = _default;
});