enifed('ember-runtime/lib/system/namespace', ['exports', 'ember-metal', 'ember-utils', 'ember-runtime/lib/system/object'], function (exports, _emberMetal, _emberUtils, _object) {
  'use strict';

  /**
    A Namespace is an object usually used to contain other objects or methods
    such as an application or framework. Create a namespace anytime you want
    to define one of these new containers.
  
    # Example Usage
  
    ```javascript
    MyFramework = Ember.Namespace.create({
      VERSION: '1.0.0'
    });
    ```
  
    @class Namespace
    @namespace Ember
    @extends EmberObject
    @public
  */
  // Preloaded into namespaces

  var Namespace = _object.default.extend({
    isNamespace: true,

    init: function () {
      (0, _emberMetal.addNamespace)(this);
    },
    toString: function () {
      var name = (0, _emberMetal.get)(this, 'name') || (0, _emberMetal.get)(this, 'modulePrefix');
      if (name) {
        return name;
      }
      (0, _emberMetal.findNamespaces)();
      name = (0, _emberUtils.getName)(this);
      if (name === undefined) {
        name = (0, _emberUtils.guidFor)(this);
        (0, _emberUtils.setName)(this, name);
      }
      return name;
    },
    nameClasses: function () {
      (0, _emberMetal.processNamespace)(this);
    },
    destroy: function () {
      (0, _emberMetal.removeNamespace)(this);
      this._super.apply(this, arguments);
    }
  }); /**
      @module ember
      */

  Namespace.reopenClass({
    NAMESPACES: _emberMetal.NAMESPACES,
    NAMESPACES_BY_ID: _emberMetal.NAMESPACES_BY_ID,
    processAll: _emberMetal.processAllNamespaces,
    byName: _emberMetal.findNamespace
  });

  exports.default = Namespace;
});