enifed('ember-runtime/lib/system/core_object', ['exports', 'ember-babel', 'container', 'ember-utils', 'ember-metal', 'ember-runtime/lib/mixins/action_handler', 'ember-runtime/lib/inject', 'ember-debug', 'ember-environment'], function (exports, _emberBabel, _container, _emberUtils, _emberMetal, _action_handler, _inject, _emberDebug, _emberEnvironment) {
  'use strict';

  var applyMixin = _emberMetal.Mixin._apply; /**
                                               @module @ember/object
                                             */

  // using ember-metal/lib/main here to ensure that ember-debug is setup
  // if present

  var reopen = _emberMetal.Mixin.prototype.reopen;

  function makeCtor(base) {
    // Note: avoid accessing any properties on the object since it makes the
    // method a lot faster. This is glue code so we want it to be as fast as
    // possible.

    var wasApplied = false,
        initFactory;
    var _Class2 = void 0;

    if (base) {
      _Class2 = function (_base) {
        (0, _emberBabel.inherits)(Class, _base);

        function Class(properties) {

          if (!wasApplied) {
            _Class2.proto(); // prepare prototype...
          }

          return (0, _emberBabel.possibleConstructorReturn)(this, _base.call(this, properties));
        }

        return Class;
      }(base);
    } else {
      initFactory = void 0;

      _Class2 = function () {
        function _Class(properties) {
          var _self, concatenatedProperties, mergedProperties, hasConcatenatedProps, hasMergedProps, keyNames, i, keyName, value, possibleDesc, isDescriptor, baseValue;

          if (!wasApplied) {
            _Class2.proto(); // prepare prototype...
          }

          var self = this;

          if (initFactory !== void 0) {
            _container.FACTORY_FOR.set(this, initFactory);
            initFactory = void 0;
          } // only used in debug builds to enable the proxy trap

          // using DEBUG here to avoid the extraneous variable when not needed


          var m = (0, _emberMetal.meta)(self);
          var proto = m.proto;
          m.proto = self;

          if (properties !== undefined) {
            false && !(typeof properties === 'object' && properties !== null) && (0, _emberDebug.assert)('EmberObject.create only accepts objects.', typeof properties === 'object' && properties !== null);
            false && !!(properties instanceof _emberMetal.Mixin) && (0, _emberDebug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin));

            concatenatedProperties = self.concatenatedProperties;
            mergedProperties = self.mergedProperties;
            hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
            hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;
            keyNames = Object.keys(properties);


            for (i = 0; i < keyNames.length; i++) {
              keyName = keyNames[i];
              value = properties[keyName];


              if (_emberEnvironment.ENV._ENABLE_BINDING_SUPPORT && _emberMetal.Mixin.detectBinding(keyName)) {
                m.writeBindings(keyName, value);
              }

              false && !!(value instanceof _emberMetal.ComputedProperty) && (0, _emberDebug.assert)('EmberObject.create no longer supports defining computed ' + 'properties. Define computed properties using extend() or reopen() ' + 'before calling create().', !(value instanceof _emberMetal.ComputedProperty));
              false && !!(typeof value === 'function' && value.toString().indexOf('._super') !== -1) && (0, _emberDebug.assert)('EmberObject.create no longer supports defining methods that call _super.', !(typeof value === 'function' && value.toString().indexOf('._super') !== -1));
              false && !!(keyName === 'actions' && _action_handler.default.detect(this)) && (0, _emberDebug.assert)('`actions` must be provided at extend time, not at create time, ' + 'when Ember.ActionHandler is used (i.e. views, controllers & routes).', !(keyName === 'actions' && _action_handler.default.detect(this)));

              possibleDesc = (0, _emberMetal.descriptorFor)(self, keyName, m);
              isDescriptor = possibleDesc !== undefined;


              if (!isDescriptor) {
                baseValue = self[keyName];


                if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
                  if (baseValue) {
                    value = (0, _emberUtils.makeArray)(baseValue).concat(value);
                  } else {
                    value = (0, _emberUtils.makeArray)(value);
                  }
                }

                if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
                  value = (0, _emberUtils.assign)({}, baseValue, value);
                }
              }

              if (isDescriptor) {
                possibleDesc.set(self, keyName, value);
              } else if (typeof self.setUnknownProperty === 'function' && !(keyName in self)) {
                self.setUnknownProperty(keyName, value);
              } else {
                self[keyName] = value;
              }
            }
          }

          if (_emberEnvironment.ENV._ENABLE_BINDING_SUPPORT) {
            _emberMetal.Mixin.finishPartial(self, m);
          }

          // using DEBUG here to avoid the extraneous variable when not needed

          (_self = self).init.apply(_self, arguments);

          m.proto = proto;
          (0, _emberMetal.finishChains)(m);
          (0, _emberMetal.sendEvent)(self, 'init', undefined, undefined, undefined, m);

          // only return when in debug builds and `self` is the proxy created above
        }

        _Class._initFactory = function (factory) {
          initFactory = factory;
        };

        return _Class;
      }();
    }

    _Class2.willReopen = function () {
      if (wasApplied) {
        _Class2.PrototypeMixin = _emberMetal.Mixin.create(_Class2.PrototypeMixin);
      }

      wasApplied = false;
    };

    _Class2.proto = function () {
      var superclass = _Class2.superclass;
      if (superclass) {
        superclass.proto();
      }

      if (!wasApplied) {
        wasApplied = true;
        _Class2.PrototypeMixin.applyPartial(_Class2.prototype);
      }

      // Native classes will call the nearest superclass's proto function,
      // and proto is expected to return the current instance's prototype,
      // so we need to return it from `this` instead
      return this.prototype;
    };

    return _Class2;
  }

  var IS_DESTROYED = (0, _emberMetal.descriptor)({
    configurable: true,
    enumerable: false,

    get: function () {
      return (0, _emberMetal.peekMeta)(this).isSourceDestroyed();
    },
    set: function (value) {
      false && !(value === IS_DESTROYED) && (0, _emberDebug.assert)('You cannot set `' + this + '.isDestroyed` directly, please use `.destroy()`.', value === IS_DESTROYED);
    }
  });

  var IS_DESTROYING = (0, _emberMetal.descriptor)({
    configurable: true,
    enumerable: false,

    get: function () {
      return (0, _emberMetal.peekMeta)(this).isSourceDestroying();
    },
    set: function (value) {
      false && !(value === IS_DESTROYING) && (0, _emberDebug.assert)('You cannot set `' + this + '.isDestroying` directly, please use `.destroy()`.', value === IS_DESTROYING);
    }
  });

  /**
    @class CoreObject
    @public
  */
  var CoreObject = makeCtor();
  CoreObject.prototype.toString = _emberMetal.classToString;
  CoreObject.toString = _emberMetal.classToString;
  (0, _emberUtils.setName)(CoreObject, 'Ember.CoreObject');

  CoreObject.PrototypeMixin = _emberMetal.Mixin.create({
    reopen: function () {
      var _len, args, _key;

      for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      applyMixin(this, args, true);
      return this;
    },
    init: function () {},

    /**
      Defines the properties that will be concatenated from the superclass
      (instead of overridden).
       By default, when you extend an Ember class a property defined in
      the subclass overrides a property with the same name that is defined
      in the superclass. However, there are some cases where it is preferable
      to build up a property's value by combining the superclass' property
      value with the subclass' value. An example of this in use within Ember
      is the `classNames` property of `Ember.View`.
       Here is some sample code showing the difference between a concatenated
      property and a normal one:
       ```javascript
      import EmberObject from '@ember/object';
       const Bar = EmberObject.extend({
        // Configure which properties to concatenate
        concatenatedProperties: ['concatenatedProperty'],
         someNonConcatenatedProperty: ['bar'],
        concatenatedProperty: ['bar']
      });
       const FooBar = Bar.extend({
        someNonConcatenatedProperty: ['foo'],
        concatenatedProperty: ['foo']
      });
       let fooBar = FooBar.create();
      fooBar.get('someNonConcatenatedProperty'); // ['foo']
      fooBar.get('concatenatedProperty'); // ['bar', 'foo']
      ```
       This behavior extends to object creation as well. Continuing the
      above example:
       ```javascript
      let fooBar = FooBar.create({
        someNonConcatenatedProperty: ['baz'],
        concatenatedProperty: ['baz']
      })
      fooBar.get('someNonConcatenatedProperty'); // ['baz']
      fooBar.get('concatenatedProperty'); // ['bar', 'foo', 'baz']
      ```
       Adding a single property that is not an array will just add it in the array:
       ```javascript
      let fooBar = FooBar.create({
        concatenatedProperty: 'baz'
      })
      view.get('concatenatedProperty'); // ['bar', 'foo', 'baz']
      ```
       Using the `concatenatedProperties` property, we can tell Ember to mix the
      content of the properties.
       In `Component` the `classNames`, `classNameBindings` and
      `attributeBindings` properties are concatenated.
       This feature is available for you to use throughout the Ember object model,
      although typical app developers are likely to use it infrequently. Since
      it changes expectations about behavior of properties, you should properly
      document its usage in each individual concatenated property (to not
      mislead your users to think they can override the property in a subclass).
       @property concatenatedProperties
      @type Array
      @default null
      @public
    */
    concatenatedProperties: null,

    /**
      Defines the properties that will be merged from the superclass
      (instead of overridden).
       By default, when you extend an Ember class a property defined in
      the subclass overrides a property with the same name that is defined
      in the superclass. However, there are some cases where it is preferable
      to build up a property's value by merging the superclass property value
      with the subclass property's value. An example of this in use within Ember
      is the `queryParams` property of routes.
       Here is some sample code showing the difference between a merged
      property and a normal one:
       ```javascript
      import EmberObject from '@ember/object';
       const Bar = EmberObject.extend({
        // Configure which properties are to be merged
        mergedProperties: ['mergedProperty'],
         someNonMergedProperty: {
          nonMerged: 'superclass value of nonMerged'
        },
        mergedProperty: {
          page: { replace: false },
          limit: { replace: true }
        }
      });
       const FooBar = Bar.extend({
        someNonMergedProperty: {
          completelyNonMerged: 'subclass value of nonMerged'
        },
        mergedProperty: {
          limit: { replace: false }
        }
      });
       let fooBar = FooBar.create();
       fooBar.get('someNonMergedProperty');
      // => { completelyNonMerged: 'subclass value of nonMerged' }
      //
      // Note the entire object, including the nonMerged property of
      // the superclass object, has been replaced
       fooBar.get('mergedProperty');
      // => {
      //   page: {replace: false},
      //   limit: {replace: false}
      // }
      //
      // Note the page remains from the superclass, and the
      // `limit` property's value of `false` has been merged from
      // the subclass.
      ```
       This behavior is not available during object `create` calls. It is only
      available at `extend` time.
       In `Route` the `queryParams` property is merged.
       This feature is available for you to use throughout the Ember object model,
      although typical app developers are likely to use it infrequently. Since
      it changes expectations about behavior of properties, you should properly
      document its usage in each individual merged property (to not
      mislead your users to think they can override the property in a subclass).
       @property mergedProperties
      @type Array
      @default null
      @public
    */
    mergedProperties: null,

    /**
      Destroyed object property flag.
       if this property is `true` the observers and bindings were already
      removed by the effect of calling the `destroy()` method.
       @property isDestroyed
      @default false
      @public
    */
    isDestroyed: IS_DESTROYED,

    /**
      Destruction scheduled flag. The `destroy()` method has been called.
       The object stays intact until the end of the run loop at which point
      the `isDestroyed` flag is set.
       @property isDestroying
      @default false
      @public
    */
    isDestroying: IS_DESTROYING,

    destroy: function () {
      var m = (0, _emberMetal.peekMeta)(this);
      if (m.isSourceDestroying()) {
        return;
      }

      m.setSourceDestroying();

      (0, _emberMetal.schedule)('actions', this, this.willDestroy);
      (0, _emberMetal.schedule)('destroy', this, this._scheduledDestroy, m);

      return this;
    },
    willDestroy: function () {},
    _scheduledDestroy: function (m) {
      if (m.isSourceDestroyed()) {
        return;
      }
      (0, _emberMetal.deleteMeta)(this);
      m.setSourceDestroyed();
    },
    toString: function () {
      var hasToStringExtension = typeof this.toStringExtension === 'function';
      var extension = hasToStringExtension ? ':' + this.toStringExtension() : '';

      var ret = '<' + ((0, _emberUtils.getName)(this) || _container.FACTORY_FOR.get(this) || this.constructor.toString()) + ':' + (0, _emberUtils.guidFor)(this) + extension + '>';

      return ret;
    }
  });

  CoreObject.PrototypeMixin.ownerConstructor = CoreObject;

  CoreObject.__super__ = null;

  function flattenProps() {
    var concatenatedProperties = this.concatenatedProperties,
        mergedProperties = this.mergedProperties,
        _len2,
        props,
        _key2,
        i,
        properties,
        keyNames,
        j,
        k,
        keyName,
        value,
        baseValue,
        _baseValue;

    var hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
    var hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;

    var initProperties = {};

    for (_len2 = arguments.length, props = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      props[_key2] = arguments[_key2];
    }

    for (i = 0; i < props.length; i++) {
      properties = props[i];


      false && !!(properties instanceof _emberMetal.Mixin) && (0, _emberDebug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin));

      keyNames = Object.keys(properties);


      for (j = 0, k = keyNames.length; j < k; j++) {
        keyName = keyNames[j];
        value = properties[keyName];


        if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
          baseValue = initProperties[keyName];


          if (baseValue) {
            value = (0, _emberUtils.makeArray)(baseValue).concat(value);
          } else {
            value = (0, _emberUtils.makeArray)(value);
          }
        }

        if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
          _baseValue = initProperties[keyName];


          value = (0, _emberUtils.assign)({}, _baseValue, value);
        }

        initProperties[keyName] = value;
      }
    }

    return initProperties;
  }

  var ClassMixin = _emberMetal.Mixin.create({
    isClass: true,

    isMethod: false,
    extend: function () {
      var Class = makeCtor(this);

      Class.ClassMixin = _emberMetal.Mixin.create(this.ClassMixin);
      Class.PrototypeMixin = _emberMetal.Mixin.create(this.PrototypeMixin);

      Class.ClassMixin.ownerConstructor = Class;
      Class.PrototypeMixin.ownerConstructor = Class;

      reopen.apply(Class.PrototypeMixin, arguments);

      Class.superclass = this;
      Class.__super__ = this.prototype;

      var proto = Class.prototype;
      (0, _emberMetal.meta)(proto).proto = proto; // this will disable observers on prototype

      Class.ClassMixin.apply(Class);
      return Class;
    },
    create: function (props, extra) {
      var C = this;

      if (extra === undefined) {
        return new C(props);
      } else {
        return new C(flattenProps.apply(this, arguments));
      }
    },
    reopen: function () {
      this.willReopen();
      reopen.apply(this.PrototypeMixin, arguments);
      return this;
    },
    reopenClass: function () {
      reopen.apply(this.ClassMixin, arguments);
      applyMixin(this, arguments, false);
      return this;
    },
    detect: function (obj) {
      if ('function' !== typeof obj) {
        return false;
      }
      while (obj) {
        if (obj === this) {
          return true;
        }
        obj = obj.superclass;
      }
      return false;
    },
    detectInstance: function (obj) {
      return obj instanceof this;
    },
    metaForProperty: function (key) {
      var proto = this.proto(); // ensure prototype is initialized
      var possibleDesc = (0, _emberMetal.descriptorFor)(proto, key);

      false && !(possibleDesc !== undefined) && (0, _emberDebug.assert)('metaForProperty() could not find a computed property with key \'' + key + '\'.', possibleDesc !== undefined);

      return possibleDesc._meta || {};
    },
    eachComputedProperty: function (callback) {
      var binding = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this;

      this.proto(); // ensure prototype is initialized
      var empty = {};

      (0, _emberMetal.meta)(this.prototype).forEachDescriptors(function (name, descriptor) {
        var _meta;

        if (descriptor.enumerable) {
          _meta = descriptor._meta || empty;

          callback.call(binding, name, _meta);
        }
      });
    }
  });

  ClassMixin.ownerConstructor = CoreObject;

  CoreObject.ClassMixin = ClassMixin;

  ClassMixin.apply(CoreObject);
  exports.default = CoreObject;
});