define("ember-bootstrap/templates/components/bs-modal/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QULtSpsx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[11,\"class\",[27,[\"modal-dialog \",[20,\"sizeClass\"],\" \",[26,\"if\",[[22,[\"centered\"]],\"modal-dialog-centered\"],null]]]],[10,\"role\",\"document\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \"],[13,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/dialog.hbs"
    }
  });

  _exports.default = _default;
});