define("ocularium-frontend-core/services/io/audioguide", ["exports", "jquery", "ocularium-shared/lib/processors/audioGuideProcessor"], function (_exports, _jquery, _audioGuideProcessor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    userService: Ember.inject.service('user'),
    crossbarService: Ember.inject.service('io/crossbar'),
    intl: Ember.inject.service('intl'),
    router: Ember.inject.service('router'),
    audioGuideProcessor: null,
    allowUserOverride: false,
    isInitialized: false,
    initialize: function initialize() {
      var _this = this;

      var reRoute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "index";
      var allowUserOverride = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.set('allowUserOverride', allowUserOverride);
      this.set('reRoute', reRoute);

      if (window.ocularium.deviceRole === "input") {
        this.setupCommandProcessors();
        this.subscribeToAudioGuideEvents();

        window.mimicAudioguideLogin = function () {
          _this.initFakeScan();

          return '-- audioguide login mimic enabled --';
        };
      }

      this.set('isInitialized', true);
    },
    setupCommandProcessors: function setupCommandProcessors() {
      var audioGuideProcessor = new _audioGuideProcessor.default();
      this.get('crossbarService').get('messageProcessor').processors["AudioGuideProcessor"] = audioGuideProcessor;
      this.set('audioGuideProcessor', audioGuideProcessor);
    },
    subscribeToAudioGuideEvents: function subscribeToAudioGuideEvents() {
      var _this2 = this;

      var agp = this.get('audioGuideProcessor');
      agp.on('newScan', function (params) {
        _this2.onScanReceived(params);
      });
      agp.on('logout', function (params) {
        _this2.onLogoutReceived(params);
      });
    },

    /* fake scans */
    initFakeScan: function initFakeScan() {
      var _this3 = this;

      document.addEventListener('keypress', function (ev) {
        var user = {};

        switch (ev.key) {
          case "g":
            user = {
              id: Math.floor(parseInt(Date.now() * Math.random())),
              name: "Gids",
              mode: "guide",
              language: "nl"
            };

            _this3.onScanReceived({
              params: {
                user: user,
                device: Number(window.ocularium.deviceId)
              }
            });

            break;

          case "t":
            user = {
              id: Math.floor(parseInt(Date.now() * Math.random())),
              name: "Gids",
              mode: "guide",
              language: "nl"
            };

            _this3.onScanReceived({
              params: {
                user: user,
                device: Number(window.ocularium.deviceId)
              }
            });

            break;

          case "v":
            user = {
              id: "" + Math.floor(parseInt(Date.now() * Math.random())),
              name: "Visitoooor",
              mode: "visitor",
              language: "nl"
            };

            _this3.onScanReceived({
              params: {
                user: user,
                device: Number(window.ocularium.deviceId)
              }
            });

            break;

          case "h":
            user = {
              id: 666,
              name: "Visitoooor",
              mode: "guide",
              language: "fr"
            };

            _this3.onScanReceived({
              params: {
                user: user,
                device: Number(window.ocularium.deviceId)
              }
            });

            break;

          case "b":
            user = {
              id: "444",
              name: "Visitoooor",
              mode: "visitor",
              language: "nl"
            };

            _this3.onScanReceived({
              params: {
                user: user,
                device: Number(window.ocularium.deviceId)
              }
            });

            break;

          case "r":
            user = {
              id: "" + Math.floor(parseInt(Date.now() * Math.random())),
              name: "V151t0r R3g15t3r3d",
              mode: "visitor",
              language: "nl"
            };

            _this3.onScanReceived({
              params: {
                user: user,
                device: Number(window.ocularium.deviceId)
              }
            });

            break;
        }
      });
    },

    /* end fake scans */
    onScanReceived: function onScanReceived(params) {
      var _this4 = this;

      var deviceId = params.params.device;

      if (Number(window.ocularium.deviceId) === deviceId) {
        var activeUser = this.get('userService').getUser();
        var scanUser = params.params.user;

        if (activeUser === null) {
          this.get('userService').setTempUser(scanUser);
          this.loginUser();
        } else {
          if (activeUser.mode !== "guide" && scanUser && parseInt(scanUser.id) !== parseInt(activeUser.tag)) {
            this.get('userService').setTempUser(scanUser);

            if (this.get('allowUserOverride')) {
              this.loginUser();
            } else {
              Ember.run.cancel(this.get('delayLoginConfirmationGone'));
              this.set('isLoginConfirmationVisible', true);
              this.set('delayLoginConfirmationGone', Ember.run.later(function () {
                _this4.set('isLoginConfirmationVisible', false);
              }, 20000));
            }
          }
        }
      }
    },
    onLogoutReceived: function onLogoutReceived(params) {
      var activeUser = this.get('userService').getUser();

      if (params && params.params && activeUser) {
        if (params.params.userId === activeUser.tag) {
          this.get('userService').clearUser();
          this.get('router').transitionTo(this.get('reRoute'));
        }
      }
    },
    loginUser: function loginUser() {
      var _this5 = this;

      Ember.run.cancel(this.get('delayUserLogin'));
      var newUser = this.get('userService').getTempUser();
      var realm = window.ocularium.crossbar.realm;
      this.get('router').transitionTo(this.get('reRoute'));
      this.set('delayUserLogin', Ember.run.later(function () {
        var prefix = window.ocularium['api']['host'];
        var url = prefix + 'rfid';
        newUser.tag = newUser.id;
        newUser.device = window.ocularium.deviceId;
        newUser.email = null;
        newUser.age = null;
        newUser.disclaimer = false;
        delete newUser.id;

        _this5.get('crossbarService').publishCrossbarMessage('be.ocular.' + realm, {
          type: "audioguide.command.logout",
          params: {
            userId: newUser.tag
          }
        });

        var promise = new Promise(function (resolve, reject) {
          _jquery.default.ajax({
            type: "GET",
            contentType: 'application/json',
            url: url + '/' + String(newUser.tag) + '?device=' + newUser.device,
            success: function success(res) {
              newUser.additionalInformation = res.additionalInformation;
              resolve();
            },
            error: function error() {
              newUser.additionalInformation = {};
              newUser.additionalInformation.isRegistered = false;
              var userData = JSON.stringify(newUser);

              _jquery.default.ajax({
                type: "POST",
                contentType: 'application/json',
                data: userData,
                url: url,
                success: function success(res) {
                  console.log("user added: ", res);
                  resolve();
                },
                error: function error(e) {
                  return reject(e);
                }
              });
            }
          });
        });
        promise.then(function () {
          _this5.get('intl').setLocale(newUser.language);

          _this5.get('userService').clearUser();

          _this5.get('userService').setUser(newUser);

          _this5.get('userService').clearTempUser(); //


          if (!_this5.get('allowUserOverride')) {
            Ember.run.cancel(_this5.get('delayLoginConfirmationGone'));

            _this5.set('isLoginConfirmationVisible', false);
          }
        }).catch(function (e) {
          return console.log("user GET failed: ", e);
        }); //
      }, 0));
    },
    updateUserData: function updateUserData() {
      var _this6 = this;

      return new Promise(function (resolve, reject) {
        var prefix = window.ocularium['api']['host'];

        var user = _this6.get('userService').getUser();

        var url = prefix + 'rfid';
        user.device = window.ocularium.deviceId;
        user.email = null;
        user.age = null;
        user.disclaimer = false;
        delete user.id;
        var userData = JSON.stringify(user);

        _jquery.default.ajax({
          type: "PUT",
          contentType: 'application/json',
          data: userData,
          url: url + '/' + String(user.tag),
          success: function success(res) {
            return resolve(res);
          },
          error: function error(e) {
            return reject(e);
          }
        });
      });
    },
    playSound: function playSound() {
      var sound = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.get('crossbarService').publishCrossbarMessage("be.ocular." + window.ocularium.crossbar.realm, {
        type: "audioguide.command.startAudio",
        params: {
          id: sound,
          deviceId: window.ocularium.deviceId
        }
      });
    }
  });

  _exports.default = _default;
});