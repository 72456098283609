define("ocularium-frontend-core/lib/prefixes/ModuleRoutePrefix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getModuleRoutePrefix = getModuleRoutePrefix;

  function getModuleRoutePrefix(moduleType) {
    function isSocial() {
      var socialRoutes = {
        "default": "modules.social." + moduleType.toString().toLowerCase()
      };
      return socialRoutes[moduleType] || socialRoutes['default'];
    }

    var routes = {
      'StretchedMediashow': function StretchedMediashow() {
        return 'modules.mediashow-stretched';
      },
      'TwitterWall': isSocial,
      'default': function _default() {
        return 'modules.' + moduleType.toString().toLowerCase();
      }
    };
    return (routes[moduleType] || routes['default'])();
  }
});