define("ocularium-frontend-core/services/data", ["exports", "ocularium-frontend-core/lib/constants/ocularErrors", "jquery", "ocularium-frontend-core/lib/data/requests"], function (_exports, _ocularErrors, _jquery, _requests) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _promises = {};

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    token: null,
    token_type: null,
    requests: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('requests', []);
    },
    getAccessToken: function getAccessToken() {
      var _this = this;

      var oculariumConfig = window['ocularium'];
      var tokenData = this.fetchTokenFromStore();

      if (tokenData && tokenData !== "undefined") {
        tokenData = JSON.parse(tokenData);

        if (this.validateToken(tokenData)) {
          this.set('token', tokenData['access_token']);
          this.set('token_type', tokenData['token_type']);
          return Promise.resolve();
        } else {
          this.removeTokenFromStore();
        }
      }

      var apiConfig = oculariumConfig["api"];
      var data = {
        'grant_type': 'client_credentials',
        'client_id': apiConfig['clientId'],
        'client_secret': apiConfig['clientSecret'],
        'scope': apiConfig['scope']
      };
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax({
          type: "POST",
          url: apiConfig['host'] + apiConfig['token'],
          data: data,
          success: function success(result) {
            _this.saveTokenToStore(result);

            _this.set('token', result['access_token']);

            _this.set('token_type', result['token_type']);

            resolve();
          },
          error: function error(_error) {
            if (_error.status === 401) {
              reject(_ocularErrors.default.UNAUTHORIZED_REQUEST);
            } else if (_error.status === 403) {
              reject(_ocularErrors.default.FORBIDDEN_REQUEST);
            } else {
              reject(_error);
            }
          }
        });
      });
    },

    /* */
    fetchTokenFromStore: function fetchTokenFromStore() {
      return localStorage.getItem("ocular_token");
    },
    saveTokenToStore: function saveTokenToStore(tokenData) {
      var date = new Date();
      date.setSeconds(date.getSeconds() + tokenData['expires_in']);
      tokenData['expires_on'] = date;
      localStorage.setItem("ocular_token", JSON.stringify(tokenData));
    },
    validateToken: function validateToken(tokenData) {
      var expires_on = new Date(tokenData['expires_on']);
      var date = new Date();
      return date < expires_on;
    },
    removeTokenFromStore: function removeTokenFromStore() {
      localStorage.removeItem('ocular_token');
    },

    /* */
    execute: function execute(request) {
      var _this2 = this;

      if (request.result) {
        return;
      }

      if (this.get('requests').filter(function (r) {
        return (0, _requests.containsRequest)(r, request.requestId);
      }).length < 1) {
        this.set('requests', this.get('requests').concat([request]));
      }

      request = (0, _requests.setStartedResult)(request);
      this.set('requests', (0, _requests.replaceRequest)(this.get('requests'), request));
      var token = this.get('token');
      var token_type = this.get('token_type');
      var apiConfig = window['ocularium']["api"];

      var promise = _jquery.default.getJSON({
        url: apiConfig['host'] + apiConfig['endpoint'] + request.url,
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', token_type + " " + token);
        }
      }).then(function (data) {
        return _this2._processSuccessResult(request, data);
      }).catch(function (reason) {
        return _this2._processFailureResult(request, reason);
      });

      _promises[request["storeKey"]["type"]] = _promises[request["storeKey"]["type"]] || {};
      _promises[request["storeKey"]["type"]][request["storeKey"]["id"]] = promise;
      return promise;
    },
    _processSuccessResult: function _processSuccessResult(request, data) {
      var _this3 = this;

      request = (0, _requests.setSuccessResult)(request, data);
      var factory = new _requests.ChildRequestFactory(this.get('store'));
      var childRequests = factory.getChildRequests(request, data);
      this.set('requests', (0, _requests.replaceRequest)(this.get('requests').concat(childRequests), request));
      var childResults = Ember.RSVP.all(childRequests.map(function (r) {
        return _this3.execute(r);
      }).concat([factory.saveToStore(request, data)]));
      return childResults;
    },
    _processFailureResult: function _processFailureResult(request, reason) {
      request = (0, _requests.setFailureResult)(request, reason);
      this.set('requests', (0, _requests.replaceRequest)(this.get('requests'), request));
      console.warn("Request failed: ", request.url, reason);
      return reason;
    },

    /* */
    reloadPage: function reloadPage() {
      window.location.reload();
    },
    reloadContent: function reloadContent() {
      var _this4 = this;

      var store = this.store; // unload all models

      var keys = Object.keys(store._identityMap._map);

      for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
        var value = _keys[_i];
        store.unloadAll(value);
      } // clear localForage


      Ember.run.scheduleOnce('destroy', function () {
        window.localforage.clear().then(function () {
          _this4.reloadPage();
        });
      });
    }
  });

  _exports.default = _default;
});