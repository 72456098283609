define("ocularium-frontend-core/services/themes", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    bugsnagService: Ember.inject.service('monitoring/bugsnag'),
    activeTheme: null,
    getActiveTheme: function getActiveTheme() {
      return this.get('activeTheme');
    },
    getDeviceData: function getDeviceData(deviceId) {
      return this.get('store').findRecord('device', deviceId).then(function (deviceData) {
        return deviceData;
      });
    },
    getActiveThemeData: function getActiveThemeData(deviceId) {
      var _this = this;

      console.log('-- setting theme --');
      return new Promise(function (resolve, reject) {
        _this.getDeviceData(deviceId).then(function (deviceData) {
          return _this.get('store').findRecord('theme-settings', deviceData.get('activeThemeId')).then(function (theme) {
            return Ember.RSVP.all([_this.setActiveTheme(theme)]).then(function () {
              resolve(theme);
            });
          });
        }).catch(function (reason) {
          reject(reason);
        });
      });
    },
    setActiveTheme: function setActiveTheme(theme) {
      this.set('activeTheme', theme);
      this.setupOculariumStyles(theme);
      this.addArtStyle(theme['artStyle']);
      var bsClient = this.get('bugsnagService.bugsnagClient');
      bsClient['metaData']['settings']['theme'] = theme.id;
    },
    setupOculariumStyles: function setupOculariumStyles(theme) {
      var bodyFont = theme.get('bodyFontFamily');
      var headerFont = theme.get('headerFontFamily');
      var oculariumStyles = '<style id="themeStyles" type="text/css">';
      /* font faces */

      oculariumStyles += this.addFontFace(bodyFont, "bodyFont");
      oculariumStyles += this.addFontFace(headerFont, "headerFont");
      oculariumStyles += this.addColorVariable("accent", theme.get("accentColor"));

      if (theme.get('css')) {
        oculariumStyles += theme.get('css').toString();
      }

      oculariumStyles += '</style>';
      (0, _jquery.default)('head').append(oculariumStyles);
    },
    addFontFace: function addFontFace(font, family) {
      if (font && font.path) {
        return "@font-face {\n" + "\tfont-family: \"" + family + "\";\n" + "\tsrc: url('" + font.path + "');\n" + "}\n";
      } else {
        console.log('-- ', family, 'not found! --');
        return "";
      }
    },
    addColorVariable: function addColorVariable(name, color) {
      var blendColor = '#ffffff';
      var calcC = color.substring(1); // strip #

      var rgb = parseInt(calcC, 16); // convert rrggbb to decimal

      var r = rgb >> 16 & 0xff; // extract red

      var g = rgb >> 8 & 0xff; // extract green

      var b = rgb >> 0 & 0xff; // extract blue

      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

      if (luma > 128) {
        blendColor = '#000000';
      }

      var colorVar = "";
      colorVar += ":root {\n" + "\t--c-" + name + ": " + color + ";\n" + "\t--c-" + name + "-blend: " + blendColor + ";\n" + "}\n";
      return colorVar;
    },
    addArtStyle: function addArtStyle(name) {
      if (!name) {
        return;
      }

      (0, _jquery.default)('body').addClass(name);
    }
  });

  _exports.default = _default;
});