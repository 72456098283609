define("ember-bootstrap/templates/components/bs-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CwOdXAHI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,[\"icon\"]]],null,{\"statements\":[[6,\"i\"],[11,\"class\",[20,\"icon\"]],[8],[9],[0,\" \"]],\"parameters\":[]},null],[1,[20,\"text\"],false],[13,1,[[26,\"hash\",null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[22,[\"isFulfilled\"]],[22,[\"isPending\"]],[22,[\"isRejected\"]],[22,[\"isSettled\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button.hbs"
    }
  });

  _exports.default = _default;
});