enifed('ember-runtime/lib/mixins/container_proxy', ['exports', 'ember-metal'], function (exports, _emberMetal) {
  'use strict';

  /**
    ContainerProxyMixin is used to provide public access to specific
    container functionality.
  
    @class ContainerProxyMixin
    @private
  */

  /**
  @module ember
  */
  exports.default = _emberMetal.Mixin.create({
    /**
     The container stores state.
      @private
     @property {Ember.Container} __container__
     */
    __container__: null,

    ownerInjection: function () {
      return this.__container__.ownerInjection();
    },
    lookup: function (fullName, options) {
      return this.__container__.lookup(fullName, options);
    },
    destroy: function () {
      var container = this.__container__;

      if (container) {
        (0, _emberMetal.join)(function () {
          container.destroy();
          (0, _emberMetal.schedule)('destroy', container, 'finalizeDestroy');
        });
      }

      this._super();
    },
    factoryFor: function (fullName) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.__container__.factoryFor(fullName, options);
    }
  });
});