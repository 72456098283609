enifed('ember-application/lib/system/engine-instance', ['exports', 'ember-babel', 'ember-utils', 'ember-runtime', 'ember-debug', 'container', 'ember-application/lib/system/engine-parent'], function (exports, _emberBabel, _emberUtils, _emberRuntime, _emberDebug, _container, _engineParent) {
  'use strict';

  var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['-bucket-cache:main'], ['-bucket-cache:main']),
      _templateObject2 = (0, _emberBabel.taggedTemplateLiteralLoose)(['template-compiler:main'], ['template-compiler:main']);

  /**
    The `EngineInstance` encapsulates all of the stateful aspects of a
    running `Engine`.
  
    @public
    @class EngineInstance
    @extends EmberObject
    @uses RegistryProxyMixin
    @uses ContainerProxyMixin
  */

  var EngineInstance = _emberRuntime.Object.extend(_emberRuntime.RegistryProxyMixin, _emberRuntime.ContainerProxyMixin, {
    /**
      The base `Engine` for which this is an instance.
       @property {Engine} engine
      @private
    */
    base: null,

    init: function () {
      this._super.apply(this, arguments);

      (0, _emberUtils.guidFor)(this);

      var base = this.base;

      if (!base) {
        base = this.application;
        this.base = base;
      }

      // Create a per-instance registry that will use the application's registry
      // as a fallback for resolving registrations.
      var registry = this.__registry__ = new _container.Registry({
        fallback: base.__registry__
      });

      // Create a per-instance container from the instance's registry
      this.__container__ = registry.container({ owner: this });

      this._booted = false;
    },
    boot: function (options) {
      var _this = this;

      if (this._bootPromise) {
        return this._bootPromise;
      }

      this._bootPromise = new _emberRuntime.RSVP.Promise(function (resolve) {
        return resolve(_this._bootSync(options));
      });

      return this._bootPromise;
    },
    _bootSync: function (options) {
      if (this._booted) {
        return this;
      }

      false && !(0, _engineParent.getEngineParent)(this) && (0, _emberDebug.assert)("An engine instance's parent must be set via `setEngineParent(engine, parent)` prior to calling `engine.boot()`.", (0, _engineParent.getEngineParent)(this));

      this.cloneParentDependencies();

      this.setupRegistry(options);

      this.base.runInstanceInitializers(this);

      this._booted = true;

      return this;
    },
    setupRegistry: function () {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.__container__.lookup('-environment:main');

      this.constructor.setupRegistry(this.__registry__, options);
    },
    unregister: function (fullName) {
      this.__container__.reset(fullName);
      this._super.apply(this, arguments);
    },
    buildChildEngineInstance: function (name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var Engine = this.lookup('engine:' + name);

      if (!Engine) {
        throw new _emberDebug.Error('You attempted to mount the engine \'' + name + '\', but it is not registered with its parent.');
      }

      var engineInstance = Engine.buildInstance(options);

      (0, _engineParent.setEngineParent)(engineInstance, this);

      return engineInstance;
    },
    cloneParentDependencies: function () {
      var _this2 = this;

      var parent = (0, _engineParent.getEngineParent)(this);

      ['route:basic', 'service:-routing', 'service:-glimmer-environment'].forEach(function (key) {
        return _this2.register(key, parent.resolveRegistration(key));
      });

      var env = parent.lookup('-environment:main');
      this.register('-environment:main', env, { instantiate: false });

      var singletons = ['router:main', (0, _container.privatize)(_templateObject), '-view-registry:main', 'renderer:-' + (env.isInteractive ? 'dom' : 'inert'), 'service:-document', (0, _container.privatize)(_templateObject2)];

      if (env.isInteractive) {
        singletons.push('event_dispatcher:main');
      }

      singletons.forEach(function (key) {
        return _this2.register(key, parent.lookup(key), { instantiate: false });
      });

      this.inject('view', '_environment', '-environment:main');
      this.inject('route', '_environment', '-environment:main');
    }
  });

  EngineInstance.reopenClass({
    setupRegistry: function (registry, options) {
      // when no options/environment is present, do nothing
      if (!options) {
        return;
      }

      registry.injection('view', '_environment', '-environment:main');
      registry.injection('route', '_environment', '-environment:main');

      if (options.isInteractive) {
        registry.injection('view', 'renderer', 'renderer:-dom');
        registry.injection('component', 'renderer', 'renderer:-dom');
      } else {
        registry.injection('view', 'renderer', 'renderer:-inert');
        registry.injection('component', 'renderer', 'renderer:-inert');
      }
    }
  });

  exports.default = EngineInstance;
});