define("ocularium-frontend-core/services/io/crossbar", ["exports", "autobahn", "ocularium-shared/lib/messageProcessor", "ocularium-frontend-core/lib/constants/ocularErrors"], function (_exports, _autobahn, _messageProcessor, _ocularErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _connection = null;
  var _messageId = 1;
  var _session = null;

  var _default = Ember.Service.extend({
    scenarioService: Ember.inject.service('scenario'),
    errorService: Ember.inject.service('monitoring/error'),
    dataService: Ember.inject.service('data'),
    session: null,
    messageProcessor: null,
    retries: 0,
    maxRetries: 4,
    setupAutobahn: function setupAutobahn(config) {
      var _this = this;

      console.log("-- setting up autobahn connection & session --");
      return new Promise(function (resolve, reject) {
        _connection = new _autobahn.default.Connection({
          url: config.host,
          realm: config.realm,
          max_retries: _this.get('maxRetries') - 1,
          authid: config.user,
          authmethods: ["wampcra"],
          onchallenge: function onchallenge(session, method, extra) {
            return _this.onChallenge(session, method, extra, config.key);
          }
        });

        _connection.onopen = function (session
        /*, details*/
        ) {
          console.log("-- autobahn connection opened --");

          _this.subscribeToSession(session, config);

          _session = session;

          _this.set('session', session);

          _this.set('retries', 0);

          resolve(session);
        };

        _connection.onclose = function (reason, details) {
          if (details.reason === 'wamp.error.no_such_realm') {
            reject(_ocularErrors.default.CROSSBAR_NO_REALM);
          }

          _this.set('retries', _this.get('retries') + 1);

          reject(reason);
        };

        _connection.open();
      });
    },
    onNumRetriesChanged: Ember.observer('retries', function () {
      if (this.get('retries') === this.get('maxRetries')) {
        this.setCrossbarErrorMessage(_ocularErrors.default.CROSSBAR_HOST_UNREACHABLE);
      }
    }),
    setCrossbarErrorMessage: function setCrossbarErrorMessage(error) {
      var errorService = this.get('errorService');
      errorService.setError(error);
      errorService.showAlert();
    },
    onChallenge: function onChallenge(session, method, extra, key) {
      if (method === "wampcra") {
        return _autobahn.default.auth_cra.sign(key, extra.challenge);
      } else {
        throw "don't know how to authenticate using '" + method + "'";
      }
    },
    subscribeToSession: function subscribeToSession(session, config) {
      var _this2 = this;

      session.subscribe('be.ocular.' + config.realm, function (args, kwargs) {
        try {
          _this2.get('messageProcessor').process(kwargs.type, kwargs);
        } catch (e) {
          console.error('Crossbar message processor error occurred: ', e);
        }
      });
    },

    /* */
    setupProcessors: function setupProcessors(session) {
      var _this3 = this;

      console.log("-- setting up crossbar processors --");
      return new Promise(function (resolve) {
        var server = new _messageProcessor.OculariumServer(session);
        var messageProcessor = new _messageProcessor.CrossbarMessageProcessor(server, {
          "ScenarioCommandProcessor": function ScenarioCommandProcessor(processor) {
            return _this3.subscribeToScenarioCommandProcessorEvents(processor);
          },
          "DeviceCommandProcessor": function DeviceCommandProcessor(processor) {
            return _this3.subscribeToDeviceCommandProcessorEvents(processor);
          }
        });

        _this3.set('messageProcessor', messageProcessor);

        resolve(messageProcessor);
      });
    },
    subscribeToScenarioCommandProcessorEvents: function subscribeToScenarioCommandProcessorEvents(processor) {
      var scenarioManager = this.get('scenarioService.scenarioManager');
      processor.on('next', function () {
        return scenarioManager.sendCommand('NEXT');
      });
      processor.on('pause', function () {
        return scenarioManager.sendCommand('PAUSE');
      });
      processor.on('reset', function () {
        return scenarioManager.sendCommand('RESET');
      });
      processor.on('previous', function () {
        return scenarioManager.sendCommand('PREVIOUS');
      });
      processor.on('play', function () {
        return scenarioManager.sendCommand('PLAY');
      });
      processor.on('goton', function (params) {
        return scenarioManager.sendCommand('GOTO_N', params.n);
      });
    },
    subscribeToDeviceCommandProcessorEvents: function subscribeToDeviceCommandProcessorEvents(processor) {
      var dataService = this.get('dataService');
      processor.on('reloadPage', function () {
        return dataService.reloadPage();
      });
      processor.on('reloadContent', function () {
        return dataService.reloadContent();
      });
    },

    /* */
    publishCrossbarMessage: function publishCrossbarMessage(command, params) {
      var session = _session;
      params = params || {};
      params.messageId = String(session.id).slice(-4) + ':' + _messageId++;
      console.log("CB >> MSG " + params.messageId, command, params);
      session.publish(command, [], params);
    }
  });

  _exports.default = _default;
});